import { Flex } from "@chakra-ui/react";
import NavBar from "../components/Navbar";
import StatusBar from "../components/StatusBar";
import UserPortfolio from "../components/Portfolio";
import MobileNavbar from "../components/Navbar/MobileNavbar";
import Sidebar from "../components/Sidebar";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export default function Portfolio(){
  const [displayChain, setDisplayChain] = useState<string>('Cross')
  const route = useLocation()

  return(
    <Flex minH='calc(100vh)' width={'100%'} flexDir={'column'} justifyContent='space-between'>
      <NavBar/>
      <Flex width={'100%'} flexDir={'row'}>
        <Flex width={"18%"}>
          <Sidebar pathName={route.pathname} displayChain={displayChain} setDisplayChain={setDisplayChain}/>
        </Flex>
        <Flex width={'82%'} justifyContent='center'>
          <UserPortfolio displayChain={displayChain}/>
        </Flex>
      </Flex>
      <Flex width={'full'} flexDir='column'>
        <MobileNavbar/>
        <StatusBar/>
      </Flex>
    </Flex>
  )
}
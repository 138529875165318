export function countDecimals(value: string) {
  // if (Math.floor(value.valueOf()) === value.valueOf()) return 0;
  if (value.indexOf(".") !== -1 && value.indexOf("-") !== -1) {
    return Number(value.split("-")[1]) || 0;
  } else if (value.indexOf(".") !== -1) {
    return Number(value.split(".")[1].length) || 0;
  }
  return Number(value.split("-")[1]) || 0;
}

export const roundDownTo = (value: number, decimal:number) => {
  decimal = decimal || 0
  return (Math.floor(value * Math.pow(10, decimal))) / Math.pow(10, decimal)
}

export function countDecimalsWithNeg(value: string) {
  // if (Math.floor(value.valueOf()) === value.valueOf()) return 0;
  if (value.indexOf(".") !== -1) {
    return Number(value.split("-")[1]) || 0;
  } else if (value.indexOf(".") !== -1) {
    return Number(value.split(".")[1].length) || 0;
  }
  return 0;
}

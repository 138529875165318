export function pickColorByChain(chain:string){
  if(chain === 'zkSync') return 'linear-gradient(to right, #5639A8, #7C6EA2)'
  if(chain === 'Arbitrum') return 'linear-gradient(to right, #1D40B5, #7792ed)'
  if(chain === 'Optimism') return 'linear-gradient(to right, #870000, #ff1111)'
  if(chain === 'Linea') return 'linear-gradient(to right, #2c56dd, #30dad8)'
  if(chain === 'Polygon') return 'linear-gradient(to right,  #330066, #7F00FF)'
  if(chain === 'Scroll') return 'linear-gradient(to right,  #e96308, #d7af94)'
  if(chain === 'Base') return 'linear-gradient(to right,  #0a59ff, #07276e)'
  if(chain === "Manta") return 'linear-gradient(to right, #24c8be, #f050b3)'
  if(chain === 'Ethereum' ||chain === 'Goerli') return 'linear-gradient(to right, #5c657b, #7b7f8a)'
  if(chain === 'Cross') return 'linear-gradient(45deg, #b21f1f, #e73c7e, #0080FF, #291E73)'
  return 'linear-gradient(to right, #606060, #606060)'
}
export function pickColorByChainId(colorMode: "light" | "dark", chainId:number){
  if(chainId === 324) return "#4F439C"
  if(chainId === 42161) return "#7792ed"
  if(chainId === 10) return "#D50000"
  if(chainId === 59144) return "#2c56dd"
  if(chainId === 1442) return "#8608A5"
  if(chainId === 534353) return "#cf7e49"
  if(chainId === 84531) return "#0a59ff"
  if(chainId === 169) return "#0690fd"
  if(chainId === 1 || chainId === 5) return "#343434"
  return "#606060"
}

export function pickBgColorByChainId(colorMode: "light" | "dark", chainId:number){
  if(colorMode === "light"){
    switch(chainId){
      case 324:
        return "#DBD9EB";
      case 42161:
        return "#E3E9FB";
      case 10: 
        return "#FAE5E5";
      case 59144:
        return "#E9EEFB";
      case 1442:
        return "#F2E6F6";
      case 534353:
        return "#FAF2EC";
      case 84531:
        return "#E6EEFF";
      case 169:
        return "#0690fd";
      case 1:
      case 5:
        return "#EAEAEA";
      default:
        return "#EFEFEF"
    }
  } else {
    switch(chainId){
      case 324:
        return "#4F439C";
      case 42161:
        return "#7792ed";
      case 10: 
        return "#D50000";
      case 59144:
        return "#2c56dd";
      case 1442:
        return "#8608A5";
      case 534353:
        return "#cf7e49";
      case 84531:
        return "#0a59ff";
      case 169:
        return "#0690fd";
      case 1:
      case 5:
        return "#343434";
      default:
        return "#606060"
    }
  }
}

export function generateRandColorArr(arrLength: number, colorMode:"light" | "dark"){
  let colorArr: string[] = []
  for(let k = 0; k < arrLength; k++){
    let color: string
    if(colorMode === "light"){
      color = "hsl(" + Math.random() * 360 + ", 100%, 25%)";
    } else {
      color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
    }
    colorArr.push(color)
  }
  return colorArr
}
import { createWeb3Modal } from '@web3modal/wagmi/react'

import { http, createConfig, WagmiProvider } from 'wagmi'
import { zkSync, arbitrum, optimism, linea, base, manta, mainnet } from 'wagmi/chains'
import { walletConnect, injected } from 'wagmi/connectors'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

const projectId = 'fdf65b60195c88cca4bd03e64bc2d771'

const metadata = {
  name: 'MES',
  description: 'MES Protocol',
  url: 'https://app.mesprotocol.com', // origin must match your domain & subdomain
  icons: ['https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/mes_logos/logo_bluecircle_svg.svg']
}

export const config = createConfig({
  chains: [zkSync, arbitrum, optimism, linea, base, manta, mainnet],
  transports: {
    [zkSync.id]: http("https://mainnet.era.zksync.io"),
    [arbitrum.id]: http("https://arb1.arbitrum.io/rpc"),
    [optimism.id]: http('https://mainnet.optimism.io'),
    [linea.id]: http('https://linea-mainnet.infura.io/v3'),
    [base.id]: http('https://mainnet.base.org'),
    [manta.id]: http("https://pacific-rpc.manta.network/http"),
    [mainnet.id]: http('https://rpc.ankr.com/eth'),
  },
  connectors: [
    injected({ shimDisconnect: true }),
    walletConnect({ projectId, metadata, showQrModal: false }),
  ]
})

createWeb3Modal({
  wagmiConfig: config,
  projectId,
})

export function Web3Modal({ children }:any) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
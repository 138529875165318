import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const mpStaticSlice = createSlice({
  name: "mpStatic",
  initialState: {
    currentNonce: 0,
  },
  reducers: {
    setCurrentNonce: (state, action) => {
      const lastNonce = action.payload
      return {
        ...state,
        currentNonce: Number(lastNonce) + 1
      }
    },
  }
});

export const {setCurrentNonce} =
  mpStaticSlice.actions;

  export const selectCurrentNonce = (state:RootState) => state.mpStatic.currentNonce;

export default mpStaticSlice.reducer;

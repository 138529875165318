import { Flex, Image, Text } from "@chakra-ui/react";

export default function RecordNotFound({message} : {message:string}) {
  return(
    <Flex
      alignItems={'center'}
      alignSelf={'center'}
      flexDirection="column"
      justifyContent={'center'}
      gap={0}
    >
      <Image 
        src= {'/order_not_found.svg'} 
        width={{base:'100px', md: '150px'}}
        alt='order_not_found'
        />
      <Text mt={-4} fontSize={'13px'} color={'gray.300'}> {message} </Text>
    </Flex>
  )
}

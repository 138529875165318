import { CloseIcon } from "@chakra-ui/icons";
import { Button, Flex, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, VStack, useColorModeValue} from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import * as Router from "react-router-dom";
import { useWebsocket } from "../../context/useWebsocket";
import { eip712WithdrawRequestTypes, getDataDomain } from "../../utils/signTypedDataMessages";
import { useSelector } from "react-redux";
import WithdrawInputBox from "./WithdrawInputBox";
import { BalanceByChainProps } from "../../types";
import { selectBalanceByChain } from "../../features/userPortfolioSlice";
import { selectMarketByChain } from "../../features/mesMarketSlice";
import { useAccount } from "wagmi";
import { signMessage, signTypedData } from "@wagmi/core";
import { config } from "../../utils/wallet";


export default function SingleWithdrawalProgressBox({
  symbol,
  isOpen, 
  onClose
}:{
  symbol: string,
  isOpen: boolean, 
  onClose:() => void
}){
  const [withdrawAmount, setWithdrawAmount] = useState<string>("");
  const [withdrawableNetBal, setWithdrawableNetBal] = useState<string>("");
  const [withdrawalAddress, setWithdrawalAddress] = useState("");
  const [isAdvanceModeEnabled, setIsAdvanceModeEnabled] = useState<boolean>(false);
  const { activeStep, setStep, nextStep, reset } = useSteps({initialStep: 0})
  const [stepState, setStepState] = useState<'loading' | 'error' | undefined>('loading')
  const [errorMsg, setErrorMsg] = useState("")
  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const msgBoxBgColor = useColorModeValue('gray.50', 'gray.700');
  const balanceByChain : BalanceByChainProps = useSelector(selectBalanceByChain)
  const {socket} = useWebsocket();
  const marketByChain = useSelector(selectMarketByChain);
  const { address, chainId } = useAccount();

  const withdrawSteps = [
    {label: "Input Withdraw Amount", 
      content: 
      <WithdrawInputBox 
        symbol={symbol} 
        withdrawableNetBal={withdrawableNetBal}
        withdrawAmount={withdrawAmount}
        setWithdrawAmount={setWithdrawAmount}
        withdrawalAddress={withdrawalAddress}
        setWithdrawalAddress={setWithdrawalAddress}
        isAdvanceModeEnabled={isAdvanceModeEnabled}
        setIsAdvanceModeEnabled={setIsAdvanceModeEnabled}
        nextStep={nextStep}
        signWithdrawRequest={signWithdrawRequest}
        />
    },
    {label: "Sign Message", content: 'Sign the message in your wallet to create a withdrawal request.'}, 
    {label: "Request Submitted", content: ''}, 
  ]

  useEffect(() => {
    if(
      //user open the progress box for the first time
      isOpen && activeStep === 0 ||
      //user already completed a transfer, but did not refresh the page
      isOpen && activeStep === withdrawSteps.length
    ) {
      resetProgressBox()
    }
  }, [isOpen])

  //refresh chain balance when switch account/chain
  useEffect(() => {
    //find withdrawable balance on current chain
    const currentChain = marketByChain.find((market) => market.chain.chainId == chainId)
    if(!currentChain) return
    const currentChainBalRecs = balanceByChain[currentChain.chain.name]
    if(!currentChainBalRecs) return
    const balRec = currentChainBalRecs.find((bal) => bal.symbol === symbol)
    if(!balRec) return
    setWithdrawableNetBal(balRec.balance.toString())
  }, [address, chainId]);

  function registerOneTimeListeners(){
    socket.removeAllListeners('withdrawalError');
    socket.removeAllListeners('withdrawalVerified');
    socket.once('withdrawalError', (msg:string) => {
      setStepState('error')
      setErrorMsg(msg)
    })
    socket.once('withdrawalVerified', async() => {
      setStep(3);
    })
  }

  function resetProgressBox(){
    setStepState('loading');
    setWithdrawAmount("");
    reset();
    registerOneTimeListeners();
  }
  
  async function signWithdrawRequest(){
    if(!address || !chainId ) return
    try{
      const ethersAddress =  ethers.utils.getAddress(address);
      const values = {
        route: "Single",
        userAddress: address,
        symbol: symbol,
        destinationChainId: chainId.toString(),
        withdrawAmount: Number(withdrawAmount).toString()
      }
      const dataDomain = getDataDomain(chainId)
      const signedMessage = await signTypedData(config, {
        types: eip712WithdrawRequestTypes,
        primaryType: "WithdrawRequest",
        account: ethersAddress as `0x${string}`,
        domain: dataDomain,
        message: values
      })
      const newWithdrawRequest = {
        op: "createSingleWithdrawRequest",
        args: [{
          userAddress: address,
          symbol: symbol,
          destinationChainId: chainId.toString(),
          withdrawAmount: Number(withdrawAmount).toString(),
          withdrawalAddress: isAdvanceModeEnabled? withdrawalAddress : null,
          signature: signedMessage
        }]
      }
      socket.emit('message', JSON.stringify(newWithdrawRequest))
    } catch (err){
      setStepState('error')
      setErrorMsg('Error in signing message...Please try again.')
    }
  }

  return(
    <Modal isOpen={isOpen} onClose = {onClose} closeOnOverlayClick={false} isCentered motionPreset='slideInBottom' size={'4xl'}>
      <ModalOverlay/>
      <ModalContent backgroundColor={bgColor}>
        <ModalHeader fontSize={'md'}> {`Withdraw ${Number(withdrawAmount) > 0 ? Number(withdrawAmount) : ""} ${symbol} : Single Chain Mode`}</ModalHeader>
        <ModalCloseButton/>
        <ModalBody py={2}>
          <Flex flexDir="column" width="100%">
            <Steps 
              variant={"circles-alt"}
              activeStep={activeStep} 
              state={stepState} 
              errorIcon={CloseIcon}
              >
              {withdrawSteps.map(({ label, content }) => {
                return(
                  <Step 
                    label={label} 
                    key={label} 
                    mb={6}
                    >
                    {stepState === 'error'
                      ? 
                      <Flex align={'center'} justify={'center'} bg={msgBoxBgColor} width='100%' rounded={'md'} px={12} py={6} fontSize='md' my={4}>
                        <VStack spacing={8}>
                            <Text>{errorMsg}</Text> 
                        </VStack>
                      </Flex>
                      : 
                      <Flex align={'center'} justify={'center'} bg={msgBoxBgColor} width='100%' rounded={'md'} p={12} fontSize='md' my={4}>
                        {content}
                      </Flex>
                    }
                  </Step>
                )}
              )}
            </Steps>
            {activeStep === withdrawSteps.length && stepState !== 'error' ?
              <Flex flexDir={'column'} align={{base: "flex-start", sm: "center"}} justify={'center'} bg={msgBoxBgColor} width='100%' rounded={'md'} p={12} fontSize='md' my={4}>
                <Text>Please wait while the withdrawal request is being processed. </Text>
                <Flex flexDir={{base: 'column', sm: 'row'}} whiteSpace='nowrap'>
                  <Text>{"You may go to "}{" "}</Text>
                  <Router.Link to={"/transfers"}> 
                    <Flex flexDir={'row'} alignItems="center" fontWeight={'extrabold'} color={useColorModeValue("cyan.400", "cyan.500")}>
                      <Text>Transfer Records</Text>
                      <FiExternalLink/>
                    </Flex>
                  </Router.Link> 
                  <Text>{" "}{" to check the latest status."}</Text>
                </Flex>
              </Flex>
              : <></>
            }
            {activeStep === withdrawSteps.length ? (
              <Router.Link 
                to={"/transfers"}
                style={{margin: "0", padding: "0"}}
                > 
                <Flex p={4}>
                  <Button mx="auto" colorScheme="linkedin">
                      Go To Transfer Records
                  </Button>
                </Flex>
              </Router.Link> 
            ) : (
              <></>
            )}
            {stepState === 'error' ? (
              <Flex p={4} flexDir={{base: 'column', lg: 'row'}} gap={2} justifyContent='center' width={'full'}>
                <Button size="sm" onClick={resetProgressBox} colorScheme="whatsapp" m={0}>
                  Start again
                </Button>
                <Button size={'sm'} colorScheme={'facebook'} onClick={() => {window.open("https://discord.com/channels/998811781948506254/999218554916315197"), "_blank"}}> Contact Support </Button> 
              </Flex>
            ) : (
              <></>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}


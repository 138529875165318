import {
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import SingleWithdrawalProgressBox from "./SingleWithdrawalProgressBox";
import { selectMarketByChain } from "../../features/mesMarketSlice";
import { useAccount, useSwitchChain } from "wagmi";

export default function ActionButton({
  displayChain,
  actionType,
  symbol,
  colorScheme
}: {
  displayChain: string
  actionType: string
  symbol: string
  colorScheme: string
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const marketByChain = useSelector(selectMarketByChain);
  const { chainId } = useAccount();
  const { switchChain } = useSwitchChain();

  function handleButtonClick(){
    //check if currently connected chain is the same as display chain
    const selectedChain = marketByChain.find((market) => market.chain.name === displayChain)
    if(!chainId || !selectedChain) return
    if(chainId === selectedChain.chain.chainId){
      onOpen()
    } else {
      switchChain({chainId: selectedChain.chain.chainId})
    }
  }
  
  return (
    <>
      <Button 
        fontSize={{base: "11px", md: '13px'}} 
        size={"sm"} 
        colorScheme={colorScheme} 
        onClick={handleButtonClick}
        >
        {actionType}
      </Button>
      <SingleWithdrawalProgressBox
        symbol={symbol}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

import { Box, Flex, HStack, Icon, Link, Text, useColorModeValue } from "@chakra-ui/react";
import { useState } from "react";
import { IconType } from "react-icons";
import { AiOutlineMedium, AiOutlineTwitter } from "react-icons/ai";
import { BsWifi, BsWifi1, BsWifi2 } from "react-icons/bs";
import { MdWifiOff } from 'react-icons/md'
import { FaDiscord } from "react-icons/fa";
import { ReactInternetSpeedMeter } from 'react-internet-meter';

export default function StatusBar(){
  const [networkSpeed, setNetworkSpeed] = useState<string>("0");
  const bgColor = useColorModeValue('#e7edf5','#070D15');
  const linkColor = useColorModeValue('cyan.600', 'cyan.400');
  const SocialIcon = ({ label, icon, href} : {label: string, icon: IconType, href: string}) => {
    return(
      <Link
        href={href}
        role={'group'}
        isExternal={true}
        rounded={'md'}
        _hover={{
          textDecoration: 'none',
          color: linkColor,
        }}
        >
        <HStack>
          <Icon as={icon} display={'inline'}/>
          <Text fontSize={'12px'} display={{base: "none", md: "inline"}}>
            {label}
          </Text>
        </HStack>
      </Link>
    )
  }
  return(
    <>
      <ReactInternetSpeedMeter 
        pingInterval={4000}
        imageUrl="https://i.ibb.co/d4G5fqb/screenshot.png"
        downloadSize="1781287" 
        thresholdUnit='megabyte'
        callbackFunctionOnNetworkTest={(data:string) => {
          setNetworkSpeed(data)
        }}
        callbackFunctionOnNetworkDown={((err:any) => {
          setNetworkSpeed("0")
        })}
      />
      <Flex 
        zIndex={10}
        position="fixed" 
        bottom="0" 
        left="0" 
        right="0" 
        px={2}
        py={1}
        bg={bgColor}
        justifyContent='space-between'
        > 
        <HStack>
          {Number(networkSpeed) === 0
          ? <>
              <MdWifiOff color="gray.300"/>
              <Text color="gray.300" fontSize={'12px'}> Disconnected </Text>
            </>
          : Number(networkSpeed) < 8
            ? <>
                <BsWifi1 color="#f44336"/>
                <Text color="#f44336" fontSize={'12px'}> Poor Connection {Number(networkSpeed) > 0 ? `${networkSpeed} MB/s` : ""} </Text>
              </>
            : Number(networkSpeed) < 25
              ? <>
                  <BsWifi2 color="#fb8c00"/>
                  <Text color="#fb8c00" fontSize={'12px'}> Connected {Number(networkSpeed) > 0 ? `${networkSpeed} MB/s` : ""} </Text>
                </>
              : <>
                  <BsWifi color="#419E6A"/>
                  <Text color="#419E6A" fontSize={'12px'}> Connected {Number(networkSpeed) > 0 ? `${networkSpeed} MB/s` : ""} </Text>
                </>
          }
        </HStack>
        <HStack spacing={6}>
          <SocialIcon label={`Announcements`} icon={AiOutlineTwitter} href={'https://twitter.com/MESprotocol'}/>
          <SocialIcon label={`Beginner's Guide`} icon={AiOutlineMedium} href={'https://docs.mesprotocol.com/user-guide/getting-started'}/>
          <SocialIcon label={'Report Bugs'} icon={FaDiscord} href={'https://discord.com/channels/998811781948506254/1070221804808982618'}/>
        </HStack>
      </Flex>
    </>
  )
}
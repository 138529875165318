import { signTypedData } from "@wagmi/core";
import { ethers } from "ethers";
import { Socket } from "socket.io-client";
import { config } from "../utils/wallet";
import { eip712CancelAllUserOrderTypes, eip712CancelOrderTypes, getDataDomain } from "../utils/signTypedDataMessages";

export const getUserOrder = (address: string, socket: Socket) => {
  const userOrderQuery = {
    op: "getUserOrder",
    args: [{
      address: address
    }]
  };
  socket.emit("message", JSON.stringify(userOrderQuery));
};

export const getUserHooks = (address: string, socket: Socket) => {
  const userHookQuery = {
    op: "getUserHooks",
    args: [{
      address: address
    }]
  };
  socket.emit("message", JSON.stringify(userHookQuery));
}

export const getMesMarkets = (
  socket: Socket
  ) => {
  const mesMarketQuery = {
    op: "getMesMarkets",
    args: null
  };
  socket.emit('message', JSON.stringify(mesMarketQuery));
}

export const getBridgingRate = (
  sourceSym:string,
  destinationSym:string,
  sourceChainId:number,
  destinationChainId:number,
  payAmount:string,
  receiveAmount: string,
  orderType: string,
  socket: Socket
) => {
  const getBridgingRateQuery = {
    op: "getBridgingRate",
    args: [{
      sourceSym: sourceSym,
      destSym: destinationSym,
      sourceChainId: sourceChainId,
      destChainId: destinationChainId,
      payAmount: payAmount,
      receiveAmount: receiveAmount,
      orderType: orderType
    }]
  };
  socket.emit('message', JSON.stringify(getBridgingRateQuery));
}

export const cancelOrder = async (
  address: string,
  chainId: number,
  orderKey: string,
  socket: Socket
) => {
  try {
    const signTimestamp = Date.now();
    const ethersAddress = ethers.utils.getAddress(address);
    const dataDomain = getDataDomain(chainId);
    const values = {
      cancelOrderKey:orderKey,
      userAddress:address,
      chainId: chainId.toString(),
      signTimestamp:signTimestamp,
    }
    const signedMessage = await signTypedData(config, {
      types: eip712CancelOrderTypes,
      primaryType: "CancelMpOrder",
      account: ethersAddress as `0x${string}`,
      domain: dataDomain,
      message: values
    });

    const cancelOrder = {
      op: "cancelOrder",
      args: [{
        address: address,
        orderKey: orderKey,
        signature: signedMessage,
        signTimestamp: signTimestamp,
        chainId: chainId
      }]
    };
    socket.emit("message", JSON.stringify(cancelOrder));
  } catch (err) {
    console.log(err);
    throw new Error("Failed to cancel order! Please try again.");
  }
};

export const cancelAllUserOrders = async (
  address: string,
  chainId: number,
  socket: Socket
) => {
  try {
    const signTimestamp = Date.now();
    const ethersAddress = ethers.utils.getAddress(address);
    const dataDomain = getDataDomain(chainId);
    const values = {
      userAddress:address,
      signTimestamp:signTimestamp,
    }
    const signedMessage = await signTypedData(config, {
      types: eip712CancelAllUserOrderTypes,
      primaryType: "CancelAllUserOrders",
      account: ethersAddress as `0x${string}`,
      domain: dataDomain,
      message: values
    })
    
    const cancelOrder = {
      op: "cancelAllUserOrders",
      args: [{
        address: address,
        chainId: chainId,
        signature: signedMessage,
        signTimestamp: signTimestamp,
      }]
    };
    socket.emit("message", JSON.stringify(cancelOrder));
  } catch (err) {
    console.log(err);
    throw new Error("Failed to cancel order! Please try again.");
  }
};

export const updateUserBalances = (
  address: string,
  tradingPairSym: string[],
  chainId: string,
  baseOp: "ADD" | "SUB" | null,
  baseAmount: string | null,
  quoteOp: "ADD" | "SUB" | null,
  quoteAmount: string | null,
  transactionHash: string,
  socket: Socket
) => {
  const updateBalanceQuery = {
    op: "updateUserBalances",
    args: [{
      address: address,
      tradingPairSym: tradingPairSym,
      chainId: Number(chainId),
      baseOp : baseOp,
      baseAmount : baseAmount,
      quoteOp : quoteOp,
      quoteAmount: quoteAmount,
      transactionHash: transactionHash
    }]
  };
  socket.emit("message", JSON.stringify(updateBalanceQuery));
};

export const getUserPortfolio = (
  address: string,
  socket: Socket
) => {
  const getUserPortfolioQuery = {
    op: "getUserPortfolio",
    args: [{
      address: address
    }]
  };
  socket.emit("message", JSON.stringify(getUserPortfolioQuery))
}

export const getUserReceipts = (
  address: string,
  socket: Socket
) => {
  const getUserReceiptsQuery = {
    op: "getUserReceipts",
    args: [{
      address: address
    }]
  }
  socket.emit("message", JSON.stringify(getUserReceiptsQuery))
}

export const getAccountLastNonce = (
  userAddress: string,
  socket: Socket
) => {
  const getLastNonceQuery = {
    op: "getLastNonce",
    args: [{
      address: userAddress,
    }]
  }
  socket.emit("message", JSON.stringify(getLastNonceQuery))
}
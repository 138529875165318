import { Button, Flex, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, VStack, Spinner, useColorModeValue, useDisclosure} from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { BigNumber, ethers } from "ethers";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useWebsocket } from "../../context/useWebsocket";
import { CloseIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectSingleCustomRate, selectSingleBridgingRate, selectSingleBridgingDirectionDisplayId, selectSingleBridgingResults, selectSourceChain, selectDestChain, selectSourceSym, selectDestSym, selectSourceAllowance } from "../../features/mesConvertSlice";
import { selectCurrentNonce } from "../../features/mpStaticSlice";
import { useAccount } from "wagmi";
import { writeContract } from "@wagmi/core";
import { config } from "../../utils/wallet";
import { parseUnits } from "viem";
import Transfers from "../Transfers";

export default function OneClickModal({
  isOpen, 
  onClose,
  depositAmount,
  sourceQuantity,
  destQuantity,
  orderType,
  hasEditedPrice,
  hasExpiry,
  expiryDate,
  withdrawalAddress,
  handleReset,
  setIsSubmittingOrder,
  setIsLockedOrder
}:{
  isOpen: boolean, 
  onClose:() => void,
  depositAmount: string,
  sourceQuantity: string,
  destQuantity: string,
  orderType: string,
  hasEditedPrice: boolean,
  hasExpiry: boolean,
  expiryDate: string,
  withdrawalAddress: string | undefined,
  handleReset: () => void,
  setIsSubmittingOrder: Dispatch<SetStateAction<boolean>>
  setIsLockedOrder: Dispatch<SetStateAction<boolean>>
}){
  const { address, chainId } = useAccount();
  const { isOpen: isTransferOpen, onOpen: onTransferOpen, onClose: onTransferClose } = useDisclosure();
  const { activeStep, setStep, reset } = useSteps({initialStep: 0})
  const depositSteps = useRef<any[]>([])
  const [stepState, setStepState] = useState<'loading' | 'error' | undefined>('loading')
  const [errorMsg, setErrorMsg] = useState("")
  const [displayContent, setDisplayContent] = useState('')
  const [isResetting, setIsResetting] = useState(false)
  const bgColor = useColorModeValue('gray.100', 'gray.800');
  const msgBoxBgColor = useColorModeValue('gray.50', 'gray.700');
  const MPVAULT_CONTRACT_ABI = require("../../abi/mpVault.json");
  const ERC20_ABI = require("../../abi/erc20Mock.json");
  const {socket} = useWebsocket();

  const singleCustomRate = useSelector(selectSingleCustomRate);
  const bridgingRate = useSelector(selectSingleBridgingRate);
  const bridgingDirectionDisplayId = useSelector(selectSingleBridgingDirectionDisplayId);
  const currentNonce = useSelector(selectCurrentNonce);
  const bridgingResults = useSelector(selectSingleBridgingResults);
  const sourceChain = useSelector(selectSourceChain);
  const destChain = useSelector(selectDestChain);
  const sourceSym = useSelector(selectSourceSym);
  const destSym = useSelector(selectDestSym);
  const tokenAllowance = useSelector(selectSourceAllowance);

  //initial setup
  useEffect(() => {
    if(!isOpen) return
    //set deposit steps according to token allowance
    if(Number(tokenAllowance) > Number(depositAmount)){
      depositSteps.current = [
        {label: "Confirm in wallet"},
        {label: "Transaction Submitted"},
      ]
    } else {
      depositSteps.current = [
        {label: "Approve Token"},
        {label: "Confirm in wallet"},
        {label: "Transaction Submitted"},
      ]
    }
    if(//user open the progress box for the first time
      isOpen && activeStep === 0 ||
      //user already completed a transfer, but did not refresh the page
      isOpen && activeStep === depositSteps.current.length
    ) {
      resetProgressBox()
    }
    
  }, [isOpen, address, chainId])

  function registerOneTimeListeners(){
    socket.removeAllListeners("oneClickSubmitted")
    socket.removeAllListeners("depositError")
    socket.once('oneClickSubmitted', async() => {
      depositERC20OnChain(depositAmount);
    })
    socket.once('depositError', (msg:string) => {
      setStepState('error')
      setErrorMsg(msg)
    })
  }

  function resetProgressBox(){
    setIsResetting(true)
    setStepState('loading');
    reset();
    if(Number(tokenAllowance) > Number(depositAmount)){
      registerOneTimeListeners();
      setDisplayContent('Please confirm the transaction in your wallet.')
      submitOneClickTxn();
    } else {
      registerOneTimeListeners();
      setDisplayContent("Permit MES Protocol to interact with your wallet.")
      approveERC20();
    }
    setIsResetting(false)
  }


  async function depositERC20OnChain(netDepositAmount:string){
    try {
      if(!address) return
      //proceed to deposit tokens
      const depositValueInBigInt = parseUnits(
        netDepositAmount,
        sourceSym.decimal
      );
      await writeContract(config, {
        abi: MPVAULT_CONTRACT_ABI,
        address: sourceChain.vaultAddress as `0x${string}`,
        account: address,
        functionName: "deposit",
        args: [
          sourceSym.tokenAddress,
          depositValueInBigInt.toString()
        ],
        value: sourceSym.symbol === sourceChain.nativeToken.symbol ? depositValueInBigInt: undefined,
      })
      setStep(depositSteps.current.length)
    } catch (err: any) {
      console.log('deposit error', err)
      setStepState('error');
      setErrorMsg(`Error in depositing ${Number(depositAmount)} ${sourceSym.symbol}. Please try again.`)
    }
  }

  async function approveERC20() {
    if(!address) return 
    try{
      setDisplayContent("Please wait while the transaction is being confirmed.")
      await writeContract(config, {
        abi: ERC20_ABI,
        address: sourceSym.tokenAddress as `0x${string}`,
        account: address,
        functionName: "approve",
        args: [
          sourceChain.vaultAddress,
          ethers.constants.MaxUint256.sub(BigNumber.from(10)).toString()
        ]
      })
      setTimeout(() => {
        setStep(1);
        submitOneClickTxn();
      }, 2000)
    } catch (err: any) {
      console.log('Approve error', err)
      setStepState('error')
      setErrorMsg('Error in approving token. Please try again.')
    }
  }

  async function submitOneClickTxn() {
    if(!address || !chainId) return 
    const ethersAddress = ethers.utils.getAddress(address);
    setIsSubmittingOrder(true)
    setIsLockedOrder(true)
    try{
      const signTimestamp = Date.now();
      //the message to sign differs if it is under cross-chain mode
      const signMessageOrder = {
        tradingPairSym: [sourceSym.symbol, destSym.symbol],
        receiveAmount: orderType === 'Market' ? 0 : Number(destQuantity),
        payAmount: Number(sourceQuantity),
        price: hasEditedPrice ? Number(singleCustomRate) : Number(Number(bridgingRate).toFixed(destSym.displayDecimal)),
        orderDirectionDisplayId: bridgingDirectionDisplayId,
        orderType: orderType,
        route: sourceChain.chainId === destChain.chainId ? "Single" : "Cross", 
        chainId: sourceChain.chainId,
        destinationChainId: destChain.chainId,
      }
      const userSignedOrder = {
        ...signMessageOrder,
        nonce: currentNonce,
        signature: "",
        userAddress: ethersAddress,
        expiryDate: hasExpiry? expiryDate : null,
        signTimestamp: signTimestamp
      }
      const newOrder = {
        op: "submitOneClickTxn",
        args: {
          userSignedOrder: userSignedOrder,
          bridgingResults: bridgingResults,
          tokenAddress: sourceSym.tokenAddress,
          withdrawalAddress: withdrawalAddress,
        }
      }
      //emit events
      socket?.emit("message", JSON.stringify(newOrder));
    } catch (err: any) {
      toast(err.message ?? err, {
        type: "error",
        theme: "dark",
        position: "top-right",
        toastId: "orderSubmitFailed",
      });
    }
  }
  return(
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        isCentered
        motionPreset='slideInBottom'
        size={'4xl'}
        >
        <ModalOverlay/>
        <ModalContent backgroundColor={bgColor}>
          <ModalHeader fontSize={'md'} fontWeight={'extrabold'}> {`Trade ${Number(depositAmount) > 0 ? Number(depositAmount) : ""} ${sourceSym.symbol} on ${sourceChain.name ?? "Unknown Network"}`}</ModalHeader>
          <ModalCloseButton/>
          <ModalBody py={2}>
            <Flex flexDir="column" width="100%">
              {isResetting
              ? <Flex width={'100%'} justifyContent='center' height={'10'}> <Spinner/> </Flex>
              : 
                <>
                  <Steps
                    variant={"circles-alt"}
                    activeStep={activeStep} 
                    state={stepState}
                    errorIcon={CloseIcon}
                    >
                    {depositSteps.current.map(({ label }) => (
                        <Step icon={Spinner} label={label} key={label} mb={6}>
                          {stepState === 'error'
                            ? 
                            <Flex align={'center'} justify={'center'} bg={msgBoxBgColor} width='100%' rounded={'md'} px={12} py={6} fontSize='md' my={4}>
                              <VStack spacing={8}>
                                  <Text>{errorMsg}</Text> 
                              </VStack>
                            </Flex>
                            : 
                            <Flex align={'center'} justify={'center'} bg={msgBoxBgColor} width='100%' rounded={'md'} p={12} fontSize='md' my={4}>
                              {displayContent}
                            </Flex>
                          }
                        </Step>
                      ))
                    }
                  </Steps>
                  {activeStep === depositSteps.current.length && stepState !== 'error' ?
                    <Flex flexDir={'column'} align={{base: "flex-start", sm: "center"}} justify={'center'} bg={msgBoxBgColor} width='100%' rounded={'md'} p={12} fontSize='14' my={4} gap={4}>
                        <svg
                          className="checkmark"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 52 52"
                        >
                          <circle
                            className="checkmark__circle"
                            cx="26"
                            cy="26"
                            r="25"
                            fill="none"
                          />
                          <path
                            className="checkmark__check"
                            fill="none"
                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                          />
                        </svg>
                      <Text>{`Please wait patiently for on-chain transaction settlement.`}</Text>
                    </Flex>
                    : <></>
                  }
                </>
              }
              {activeStep === depositSteps.current.length ? (
                <Flex p={4}>
                  <Button mx="auto" colorScheme="linkedin" onClick={() => {
                    handleReset()
                    onTransferOpen()
                    onClose()
                  }}>
                    View Transfer Progress
                  </Button>
                </Flex>
              ) : (
                <></>
              )}
              {stepState === 'error' ? (
                <Flex p={4} flexDir={{base: 'column', lg: 'row'}} gap={2} justifyContent='center' width={'full'}>
                  <Button size="sm" onClick={resetProgressBox} colorScheme="whatsapp" m={0}>
                    Start again
                  </Button>
                  <Button size={'sm'} colorScheme={'facebook'} onClick={() => {window.open("https://discord.com/channels/998811781948506254/999218554916315197"), "_blank"}}> Contact Support </Button> 
                </Flex>
              ) : (
                <></>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Transfers isOpen={isTransferOpen} onClose={onTransferClose}/>
    </>
  )
}


import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { defaultHook } from "../constants";

export const userHookSlice = createSlice({
  name: "userHook",
  initialState: {
    open: [defaultHook],
    closed: [defaultHook]
  },
  reducers: {
    setUserHook: (state, action) => {
      const hooks = action.payload;
      return {
        ...state,
        open: hooks.open,
        closed: hooks.closed
    }
    },
  },
});

export const { setUserHook } = userHookSlice.actions;

export const selectOpenHooks = (state: RootState) => state.userHook.open;
export const selectClosedHooks = (state: RootState) => state.userHook.closed;

export default userHookSlice.reducer;

import { useEffect, useMemo, useRef } from "react"
import { debounce } from 'lodash';

export const useDebounce = (callback: () => void, delay?:number) => {
  const ref = useRef<() => void>();

  useEffect(() => {
    ref.current = callback;
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.()
    };
    
    return debounce(func, delay ?? 2000)
  }, [])

  const cancel = () => debouncedCallback.cancel()
  return {debouncedCallback, cancel}
}

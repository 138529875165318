import {
  Box,
  Flex,
  Text,
  Stack,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import * as Router from "react-router-dom";
import { RiMoneyDollarCircleLine } from 'react-icons/ri'
import { IconType } from 'react-icons';
import MesLogo from './MesLogo';
import Settings from './Settings';
import PortfolioIcon from './PortfolioIcon';

export default function NavbarItems() {
  return (
    <Box>
      <Flex
        align={'center'}
        minW={'full'}
        justifyContent={'space-between'}
        >
        <HStack
          justifyContent={'space-between'}
          spacing={4}
          >
          <MesLogo />
          <Flex display={{ base: 'none', lg: 'flex' }}>
            <DesktopNav />
          </Flex>
        </HStack>
        <Flex display={{ base: 'none', lg: 'flex' }} alignItems='center' justifyContent={'space-between'}>
          <Settings/>
          <PortfolioIcon/>
        </Flex>
        {/* Hamberger menu icon */}
        <Flex
          flexDir={'row'}
          alignItems='center'
          className='first-step-mobile'
          display={{ base: 'flex', lg: 'none' }}
          justifyItems="space-between"
          >
          <Settings/>
        </Flex>
      </Flex>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('white', 'cyan.400');
  const linkBgHoverColor = useColorModeValue('cyan.700', 'gray.800')
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Flex flexDir={'row'} whiteSpace="nowrap">
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                py={2}
                px={5}
                role={'group'}
                fontSize={'sm'}
                fontWeight={700}
                color={linkColor}
                rounded={'md'}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                  bgColor: linkBgHoverColor,
                }}>
                {navItem.label}
                <Icon 
                  color={'gray.600'} 
                  ml={1} 
                  w={5}
                  h={5} 
                  as={ChevronDownIcon} 
                  transition={'all .25s ease-in-out'}
                  _groupHover={{ 
                    color: useColorModeValue('white','gray.600'),
                    transform: 'rotate(180deg)' 
                  }}
                />
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Flex>
  );
};

const DesktopSubNav = ({ label, isDisabled, isExternal, icon, href, subLabel }: NavItem) => {
  const SubNav = () => (
      <Flex 
        flexDir={'row'} 
        align={'center'}
        role={'group'}
        width={'full'}
        p={2}
        rounded={'md'}
        color={isDisabled? "gray.500" : "inherit"}
        _hover={{ bg: useColorModeValue('gray.100', 'gray.900') }}
        >
        <Icon mr={2} w={5} h={5} as={icon} display={'inline'}/>
        <Box>
          <Text
            _groupHover={{ color: useColorModeValue('cyan.600', 'cyan.400')}}
            fontWeight={500}
            fontSize={'14px'}
            >
            {label}
          </Text>
          <Text fontSize={'11px'} display={{base: 'none', lg: 'inline'}}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'cyan.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Flex>
  )
  return (
    <>
      {!isExternal
      ? <Router.Link
        to={href? href: ""}
        className={isDisabled? "disabled-link" : ""}
        >
          <SubNav/>
        </Router.Link>
      :<Link
        href={href}
        isExternal={true}
        display={'block'}
        role={'group'}
        _hover={{ bg: useColorModeValue('gray.100', 'gray.900') }}
        >
        <SubNav/>
      </Link>
      }
    </>
  );
};

interface NavItem {
  label: string;
  isDisabled: boolean;
  isExternal: boolean;
  icon?: IconType;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Earn',
    isDisabled: false,
    isExternal: false,
    children: [
      {
        label: 'MES Pool',
        isDisabled: true,
        isExternal: false,
        icon: RiMoneyDollarCircleLine,
        subLabel: 'Provide liquidity on MES and earn trading fees',
        href: '/earn',
      },
    ],
  },
];
import { Flex, useColorModeValue, Text, Grid, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { AiFillPieChart, AiOutlineSwap } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { breakpoints } from "../../theme";

export default function MobileNavbar(){
  const bgColor = useColorModeValue('#e7edf5','#070D15');
  const iconColor = useColorModeValue("cyan.600", "cyan.400")
  const currentPath = useLocation();
  const [path, setPath] = useState<string>("spot");
  useEffect(() => {
    const pathName = currentPath.pathname.split("/")
    setPath(pathName[1])
  }, [currentPath])
  const NavbarIcon = ({title, href, selected, icon} : {title: string, href: string, selected:boolean, icon: IconType}) => {
    return(
      <Link to={href}>
        <Flex flexDir={'column'} alignItems='center' height="full" color={selected? iconColor : "inherit"}>
          <Icon as={icon} fontSize='md'/>
          <Text>{title}</Text>
        </Flex>
      </Link>
    )
  }
  return(
    <>
    {window.screen.width < Number(breakpoints.lg.slice(0, breakpoints.lg.length - 2))
    ?<Flex 
      zIndex={10}
      position="fixed" 
      bottom="6" 
      left="0" 
      right="0" 
      px={2}
      py={3}
      bg={bgColor}
      justifyContent='space-between'
      > 
      <Grid templateColumns='repeat(2, 1fr)' alignItems={'center'} fontSize={'13px'} width="full">
        <NavbarIcon title="Swap" href="/convert" selected={path === 'convert'} icon={AiOutlineSwap}/>
        <NavbarIcon title="Portfolio" href="/portfolio" selected={path === 'portfolio'} icon={AiFillPieChart}/>
      </Grid>
    </Flex>
    :<></>
    }
    </>
  )
}
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { defaultSingleBridgingResult, defaultAssetAddress, defaultNullChain } from "../constants";

export const mesConvertSlice = createSlice({
  name: 'mesConvert',
  initialState: {
    sourceChain: defaultNullChain,
    sourceSym: defaultAssetAddress[0],
    sourceAllowance: "",
    destChain: defaultNullChain,
    destSym: defaultAssetAddress[0],
    single: defaultSingleBridgingResult,
    isLoadingRate: false
  },
  reducers:{
    setSourceChain: (state, action) => {
      const sourceChain = action.payload;
      return {
        ...state,
        sourceChain: sourceChain,
      }
    },
    setSourceSym: (state, action) => {
      const sourceSym = action.payload;
      return {
        ...state,
        sourceSym: sourceSym
      }
    },
    setSourceAllowance: (state, action) => {
      const sourceAllowance = action.payload;
      return {
        ...state,
        sourceAllowance: sourceAllowance
      }
    },
    setDestChain: (state, action) => {
      const destChain = action.payload;
      return {
        ...state,
        destChain: destChain,
      }
    },
    setDestSym: (state, action) => {
      const destSym = action.payload;
      return {
        ...state,
        destSym: destSym
      }
    },
    setSingleBridgingDirectionDisplayId: (state, action) => {
      const bridgingDirectionDisplayId = action.payload
      return {
        ...state,
        single: {
          ...state.single,
          bridgingDirectionDisplayId: bridgingDirectionDisplayId
        }
      }
    },
    setSingleBridgingRate: (state, action) => {
      const bridgingRate = action.payload
      return{
        ...state,
        single: {
          ...state.single,
          bridgingRate: bridgingRate
        }
      }
    },
    setSingleConvertData: (state, action) => {
      const convertData = action.payload
      return {
        ...state,
        single: {
          ...state.single,
          ...convertData,
        },
        isLoadingRate: false
      }
    },
    setSingleRoutingPath: (state, action) => {
      const routingPath = action.payload
      return {
        ...state,
        single: {
          ...state.single,
          routingPath: routingPath
        }
      }
    },
    setSingleCustomRate: (state, action) => {
      const singleCustomRate = action.payload
      return {
        ...state,
        single: {
          ...state.single,
          customRate: singleCustomRate
        }
      }
    },
    setIsLoadingRate: (state, action) => {
      const isLoading = action.payload
      return {
        ...state,
        isLoadingRate: isLoading
      }
    },
    resetConvertData: (state) => {
      return {
        ...state,
        single: defaultSingleBridgingResult,
        isLoadingRate: false
      }
    }
  }
})

export const {
  setSourceChain,
  setSourceSym,
  setSourceAllowance,
  setDestChain,
  setDestSym,
  setSingleBridgingDirectionDisplayId, 
  setSingleBridgingRate,
  setSingleConvertData, 
  setSingleRoutingPath,
  setIsLoadingRate, 
  setSingleCustomRate,
  resetConvertData,
} = mesConvertSlice.actions

export const selectSingleBridgingResults = (state:RootState) => state.mesConvert.single
export const selectSingleCustomRate = (state:RootState) => state.mesConvert.single.customRate
export const selectSingleBridgingRate = (state:RootState) => state.mesConvert.single.bridgingRate
export const selectSingleBridgingDirectionDisplayId = (state:RootState) => state.mesConvert.single.bridgingDirectionDisplayId
export const selectSingleServerSideErrorMsg = (state:RootState) => state.mesConvert.single.serverSideErrorMsg
export const selectIsLoadingRate = (state:RootState) => state.mesConvert.isLoadingRate
export const selectSingleIsMultiRoute = (state:RootState) => state.mesConvert.single.isMultiRoute
export const selectSingleRoutingPath = (state:RootState) => state.mesConvert.single.routingPath
export const selectFeeData = (state:RootState) => state.mesConvert.single.feeData
export const selectSourceChain = (state:RootState) => state.mesConvert.sourceChain
export const selectSourceSym = (state:RootState) => state.mesConvert.sourceSym
export const selectDestChain = (state:RootState) => state.mesConvert.destChain
export const selectDestSym = (state:RootState) => state.mesConvert.destSym
export const selectSourceAllowance = (state:RootState) => state.mesConvert.sourceAllowance

export default mesConvertSlice.reducer
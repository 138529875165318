import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { defaultMarket } from "../constants";

export const mesMarketSlice = createSlice({
  name: 'mesMarkets',
  initialState: [defaultMarket],
  reducers:{
    setMesMarketByChain: (state, action) => {
      const markets = action.payload;
      return markets
    }
  }
})

export const { setMesMarketByChain } = mesMarketSlice.actions

export const selectMarketByChain = (state:RootState) => state.mesMarkets

export default mesMarketSlice.reducer
export const defaultNullToken = {
  id: 0,
  name: "",
  symbol: "",
  decimal: 0,
  displayDecimal: 0,
  logoUrl: "",
  minTradingSize: 0
}

export const defaultETH = {
  id: 0,
  name: "Ethereum",
  symbol: "ETH",
  decimal: 18,
  displayDecimal: 3,
  logoUrl: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/token_logos/eth_logo.svg",
  minTradingSize: 0.01
}

export const defaultUSDC = {
  id: 0,
  name: "USD Coin",
  symbol: "USDC",
  decimal: 6,
  displayDecimal: 3,
  logoUrl: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/token_logos/usdc_logo.svg",
  minTradingSize: 12
}

export const defaultNullChain = {
  name: "--",
  desc: "",
  chainId: 0,
  nativeToken: defaultETH,
  chainType: {name: ""},
  vaultAddress: "0x",
  lightLogo: "",
  darkLogo: "",
  rpcUrl: "",
  blockExplorerUrl: ""
}
export const defaultL1Chain = {
  name: 'Ethereum',
  desc: "Ethereum Mainnet",
  chainId: 1,
  nativeToken: defaultETH,
  vaultAddress: "0x",
  chainType: {name: "evm"},
  lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/token_logos/eth_logo.svg",
  darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/token_logos/eth_logo.svg",
  rpcUrl: "hhttps://rpc.ankr.com/eth",
  blockExplorerUrl: "https://etherscan.io"
}

export const defaultL2Chain = {
  name: "zkSync",
  desc: "zkSync Era",
  chainId: 324,
  nativeToken: defaultETH,
  vaultAddress: "0x",
  chainType: {name: "dydx"},
  lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/dydx_logo.svg",
  darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/dydx_logo.svg",
  rpcUrl: "https://mainnet.era.zksync.io",
  blockExplorerUrl: "https://explorer.zksync.io",
}

export const defaultAssetAddress = [{
  ...defaultNullToken,
  tokenAddress: "0x",
  sourceDisabled: false,
  destDisabled: false
}]

export const defaultMarket = {
  chain: defaultL2Chain,
  tokenList: defaultAssetAddress
}

export const defaultOrder = {
  id: "",
  assetPair: "--",
  baseSym: "-",
  quoteSym: "-",
  receiveAmount: 0,
  payAmount: 0,
  price: 0,
  filledBaseAmount: 0,
  filledQuoteAmount: 0,
  filledPrice: 0,
  fees: 0,
  orderDirection: "-",
  orderDirectionDisplayId: 0,
  orderRole: "",
  status: "-",
  account: "-",
  chain: "-",
  destChain: "-",
  expiryDate: 0,
  route: "",
  nonce: 0,
  type: "-",
  createdAt: "-",
  updatedAt: "-"
}

export const defaultHook = {
  id: 0,
  fromSym: defaultNullToken,
  toSym: defaultNullToken,
  sourceChain: defaultNullChain,
  destChain: defaultNullChain,
  type: "-",
  price: 0,
  filledPrice: 0,
  pay: 0,
  receive: 0,
  fees: 0,
  expiryDate: 0,
  createdAt: "-",
  depositReceiptId: 0,
  orderId: 0,
  withdrawalReceiptId: 0,
  withdrawalTransactionHash: "",
}

export const defaultSingleBridgingResult = {
  bridgingRate: 0,
  bridgingDirectionDisplayId: 0,
  feeData: {
    tradingFees: 0,
    sourceChainFeeData: 0,
    destChainFeeData: 0
  },
  isMultiRoute: false,
  routingPath: [],
  serverSideErrorMsg: "",
  customRate: ""
}

export const defaultBalanceBySym = {
  balance: 0,
  pendingTxnTotal: 0,
  netBalance: 0,
  assetValue: 0,
  chainNetBalances: [{
    chainId: 324,
    netBalance: 0
  }]
}
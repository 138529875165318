import { Box } from "@chakra-ui/react";
import NavbarItems from "./NavbarItems";
import { useColorModeValue } from "@chakra-ui/react";

export default function Navbar() {
  const bgColor = useColorModeValue('#e7edf5','#070D15')
  return(
    <Box
      bg={bgColor}
      px={4}
    >
      <NavbarItems/>
    </Box>
  )
}

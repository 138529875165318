import { Flex, useColorModeValue } from "@chakra-ui/react";
import ConvertInterface from "../components/Convert";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import StatusBar from "../components/StatusBar";
import MobileNavbar from "../components/Navbar/MobileNavbar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectMarketByChain } from "../features/mesMarketSlice";
import { useEffect } from "react";
import { setDestChain, setDestSym, setSourceChain, setSourceSym } from "../features/mesConvertSlice";
import { defaultL1Chain, defaultL2Chain, defaultUSDC } from "../constants";

export default function Convert(){
  const bgColor = useColorModeValue("#d7e1ee", "inherit");
  const {source, dest} = useParams();
  const [sourceChainId, sourceSym] = source!.split("-");
  const [destChainId, destSym] = dest!.split("-");
  const marketByChain = useSelector(selectMarketByChain);
  const dispatch = useDispatch();

  //auto fill in specified chain and sym from url
  useEffect(() => {
    try {
      if(!sourceChainId || !sourceSym || !destChainId || !destSym){
        throw Error
      }
      const sourceMarket = marketByChain.find((m) => m.chain.chainId === Number(sourceChainId))
      if(!sourceMarket) throw Error
      const userSourceSym = sourceMarket.tokenList.find((t) => t.symbol === sourceSym)
      if(!userSourceSym) throw Error
      dispatch(setSourceChain(sourceMarket.chain))
      dispatch(setSourceSym(userSourceSym))
  
      const destMarket = marketByChain.find((m) => m.chain.chainId === Number(destChainId))
      if(!destMarket) throw Error
      const userDestSym = destMarket.tokenList.find((t) => t.symbol === destSym)
      if(!userDestSym) throw Error
      dispatch(setDestChain(destMarket.chain))
      dispatch(setDestSym(userDestSym))
    } catch (error) {
      //wrong source/dest chainId/sym. Revert to default settings
      dispatch(setSourceChain(defaultL1Chain))
      dispatch(setSourceSym(defaultUSDC))
      dispatch(setDestChain(defaultL2Chain))
      dispatch(setDestSym(defaultUSDC))
    }
  }, [marketByChain])

  return(
    <Flex minH={'calc(100vh)'} width={'100%'} flexDir={'column'} justifyContent={'space-between'} bgColor={bgColor}>
      <Flex width={'full'} flexDir='column'>
        <NavBar/>
      </Flex>
      <ConvertInterface />
      <Flex width={'full'} flexDir='column'>
        <MobileNavbar/>
        <Footer/>
        <StatusBar/>
      </Flex>
    </Flex>
  )
}
import {
  Box,
  Flex,
  IconButton,
  Button,
  Fade,
  HStack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineSwap } from "react-icons/ai";
import { IoAlertCircleSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useWebsocket } from "../../context/useWebsocket";
import { selectSingleBridgingDirectionDisplayId, selectSingleBridgingRate, selectSingleServerSideErrorMsg, setIsLoadingRate, resetConvertData, setSingleBridgingDirectionDisplayId, selectSingleCustomRate, setSingleCustomRate, selectFeeData, selectSourceChain, selectSourceSym, selectDestChain, selectDestSym, setSourceChain, setSourceSym, setDestChain, setDestSym, setSourceAllowance } from "../../features/mesConvertSlice";
import { getAccountLastNonce, getBridgingRate } from "../../helpers/wssHelpers";
import { useDebounce } from "../../hooks/useDebounce";
import { AppDispatch } from "../../store";
import { roundDownTo } from "../../utils/numbers";
import DestSym from "./DestSym";
import FeesBox from "./FeesBox";
import OrderTypeSwitch from "./OrderTypeSwitch";
import RateBox from "./RateBox";
import SourceSym from "./SourceSym";
import OneClickModal from "./OneClickModal";
import ExtraSettings from "./ExtraSettings";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { readContract } from "@wagmi/core";
import { config } from "../../utils/wallet";

export default function ConvertInterface() {
  const { address, chainId } = useAccount();
  //calculate default date for order expiry input
  let today = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  const splitDate = tomorrow.toLocaleDateString().split("/")
  const defaultDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
  //states
  const [sourceQuantity, setSourceQuantity] = useState<string>("0");
  const [destQuantity, setDestQuantity] = useState<string>("0");
  const [slippagePercent, setSlippagePercent] = useState<string>("");
  const [orderType, setOrderType] = useState<string>("Market");
  const [hasExpiry, setHasExpiry] = useState<boolean>(false);
  const [expiryDate, setExpiryDate] = useState<string>(defaultDate);
  const [isEditing, setIsEditing] = useState<"sourceQty" | "destQty">();
  const [hasEditedPrice, setHasEditedPrice] = useState<boolean>(false);
  const [hasExceedNetBal, setHasExceedNetBal] = useState<boolean>(false);
  const [isSwapAmtTooSmall, setIsSwapAmtTooSmall] = useState<boolean>(false);
  const [isFeeExceedDestQty, setIsFeeExceedDestQty] = useState<boolean>(false);
  const [isLockedOrder, setIsLockedOrder] = useState<boolean>(false);
  const [isSubmittingOrder, setIsSubmittingOrder] = useState<boolean>(false);
  const [withdrawalAddress, setWithdrawalAddress] = useState<string>();

  const sourceChain = useSelector(selectSourceChain);
  const sourceSym = useSelector(selectSourceSym);
  const destChain = useSelector(selectDestChain);
  const destSym = useSelector(selectDestSym);
  const singleCustomRate = useSelector(selectSingleCustomRate);
  const bridgingRate = useSelector(selectSingleBridgingRate);
  const bridgingDirectionDisplayId = useSelector(selectSingleBridgingDirectionDisplayId);
  const serverSideErrorMsg = useSelector(selectSingleServerSideErrorMsg);
  const feeData = useSelector(selectFeeData);
  const ERC20_ABI = require("../../abi/erc20Mock.json");

  const { socket } = useWebsocket();
  const dispatch = useDispatch<AppDispatch>();
  const overallBgColor = useColorModeValue("#d7e1ee", "inherit");
  const convertBoxBgColor = useColorModeValue('white', "gray.900");
  const swapBtnBgColor = useColorModeValue('gray.100','gray.800');
  const errorBoxBgColor = useColorModeValue("red.200","red.700");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const debouncedGetBridgingRate = useDebounce(() => {
    if(isEditing === 'sourceQty'){
      getBridgingRate(sourceSym.symbol, destSym.symbol, sourceChain.chainId, destChain.chainId, sourceQuantity, "", orderType, socket)
    } else if(isEditing === 'destQty'){
      getBridgingRate(sourceSym.symbol, destSym.symbol, sourceChain.chainId, destChain.chainId, "", destQuantity, orderType, socket)
    }
  })

  async function swapDirection(){
    //swap chain and sym
    dispatch(setSourceChain(destChain))
    dispatch(setSourceSym(destSym))
    dispatch(setDestChain(sourceChain))
    dispatch(setDestSym(sourceSym))
    //swap quantity
    setSourceQuantity("0");
    setDestQuantity("0");
    //swap isEditing side
    if(isEditing === 'destQty'){
      setIsEditing('sourceQty')
    } else if(isEditing === 'sourceQty'){
      setIsEditing('destQty')
    }
    if(singleCustomRate){
      dispatch(setSingleBridgingDirectionDisplayId(bridgingDirectionDisplayId === 0? 1: 0))
    }
  }
  
  function handleReset(){
    setSourceQuantity("0");
    setDestQuantity("0");
    setHasEditedPrice(false);
    setHasExceedNetBal(false);
    setIsSwapAmtTooSmall(false);
    setIsFeeExceedDestQty(false);
    setIsLockedOrder(false);
    setIsSubmittingOrder(false);
    setHasExpiry(false);
    setExpiryDate(defaultDate);
    dispatch(setSingleCustomRate(""));
    dispatch(resetConvertData());
  }

  function handleGetPreviewRate(){
    setIsLoadingRate(true)
    if(orderType === "Market") setIsLockedOrder(true)
    if(isEditing === 'sourceQty'){
      getBridgingRate(sourceSym.symbol, destSym.symbol, sourceChain.chainId, destChain.chainId, sourceQuantity, "", orderType, socket)
    } else if (isEditing === 'destQty'){
      getBridgingRate(sourceSym.symbol, destSym.symbol, sourceChain.chainId, destChain.chainId, "", destQuantity, orderType, socket)
    }
  }
  async function getTokenAllowance(){
    if(!address) return
    if(sourceSym.tokenAddress == "0x" || sourceChain.vaultAddress == "0x") return
    if(sourceSym.symbol === sourceChain.nativeToken.symbol){
      const formattedAllowance = ethers.utils.formatEther(ethers.constants.MaxUint256)
      dispatch(setSourceAllowance(formattedAllowance))
    } else {
      const allowance = await readContract(config, {
        abi: ERC20_ABI,
        address: sourceSym.tokenAddress as `0x${string}`,
        functionName: "allowance",
        args: [address, sourceChain.vaultAddress]
      })
      const replacer = (key:any, value:any) =>
        typeof value === 'bigint' ? value.toString() : value
      const serialized = JSON.stringify(allowance, replacer)
      // dispatch(setSourceAllowance(JSON.parse(serialized)))
      dispatch(setSourceAllowance(ethers.utils.formatUnits(JSON.parse(serialized), sourceSym.decimal)))

    }
  }

  //get bridging rate
  useEffect(() => {
    //no need to auto get bridging rate if it is a market order / no input has been provided yet
    if(orderType === 'Market') return
    if(!isEditing) return
    //abort if invalid input
    if(isEditing === 'destQty' && Number(destQuantity) <= 0) return
    if(isEditing === 'sourceQty' && Number(sourceQuantity) <= 0) return 
    dispatch(setIsLoadingRate(true));
    //clean up custom rate
    if(hasEditedPrice){
      setHasEditedPrice(false);
    }
    dispatch(setSingleCustomRate(""));
    debouncedGetBridgingRate.debouncedCallback();
  }, [sourceChain, sourceSym, destChain, destSym])

  //calculate source quantity
  useEffect(() => {
    //no need to calculate if it is a market order
    if(orderType === "Market" && !isLockedOrder) return
    //no need to calculate if user is editing source quantity
    if(!isEditing || isEditing === 'sourceQty') return
    let rate: string = ""
    //deteremine rate based on whether user has edited the price
    if(orderType === "Limit" && hasEditedPrice){
      rate = singleCustomRate
    } else {
      rate = bridgingRate.toString()
    }
    //abort if invalid input
    if(Number(rate) === 0 || 
      Number(destQuantity) <= 0 || 
      serverSideErrorMsg 
    ){
      setSourceQuantity("0")
      return
    }
    //check trading size and new source quantity
    let newSourceQty: string = "--"
    if(bridgingDirectionDisplayId === 0){
      newSourceQty = roundDownTo((Number(destQuantity) * Number(rate)), destSym.displayDecimal).toString()
      setIsSwapAmtTooSmall(Number(newSourceQty) < sourceSym.minTradingSize)
    } else if(bridgingDirectionDisplayId === 1){
      newSourceQty = roundDownTo((Number(destQuantity) / Number(rate)),destSym.displayDecimal).toString()
      setIsSwapAmtTooSmall(Number(destQuantity) < destSym.minTradingSize)
    }
    if(Number(newSourceQty) <= 0){
      setIsSwapAmtTooSmall(true)
      setSourceQuantity("0")
      return
    }
    setSourceQuantity(newSourceQty)
  }, [destQuantity, bridgingRate, singleCustomRate])

  //calculate dest quantity
  useEffect(() => {
    //no need to calcualte if it is a market order
    if(orderType === 'Market' && !isLockedOrder) return
    //no need to calculate if user is editing dest quantity
    if(isEditing === 'destQty') return
    //deteremine rate based on whether user has edited the price
    let rate: string = ""
    if(orderType === 'Limit' && hasEditedPrice){
      rate = singleCustomRate
    } else {
      rate = bridgingRate.toString()
    }
    //abort if invalid input
    if(Number(rate) === 0 || 
      Number(sourceQuantity) <= 0 || 
      serverSideErrorMsg 
    ){
      setDestQuantity("0")
      return
    }
    let newDestQty: string = "--"
    //check trading size and new dest quantity depends on swap mode
    if(bridgingDirectionDisplayId === 0){
      newDestQty = roundDownTo((Number(sourceQuantity) / Number(rate)), destSym.displayDecimal).toString()
      setIsSwapAmtTooSmall(Number(sourceQuantity) < sourceSym.minTradingSize)
    } else if(bridgingDirectionDisplayId === 1){
      newDestQty = roundDownTo((Number(sourceQuantity) * Number(rate)), destSym.displayDecimal).toString()
      setIsSwapAmtTooSmall(Number(newDestQty) < destSym.minTradingSize)
    }
    if(Number(newDestQty) <= 0){
      setIsSwapAmtTooSmall(true)
      setDestQuantity("0")
      return
    }
    //calculate total fee and compare it against
    const totalFees = Number(feeData.tradingFees) + Number(feeData.sourceChainFeeData) + Number(feeData.destChainFeeData)
    if((Number(newDestQty) - totalFees) <= 0){
      setIsFeeExceedDestQty(true)
    }
    setDestQuantity(newDestQty)
  }, [sourceQuantity, bridgingRate, singleCustomRate])

  //clear payAmount/receiveAmount for market orders if user is editing either side
  useEffect(() => {
    if(orderType === 'Market'){
      if(isEditing === 'destQty') setSourceQuantity("0")
      if(isEditing === 'sourceQty') setDestQuantity("0")
    }
  }, [isEditing])
  
  //reset states when switching order type
  useEffect(() => {
    handleReset()
  }, [orderType])

  //retrieve nonce
  useEffect(() => {
    if(!address) return
    getAccountLastNonce(address, socket);
  }, [address]);

  //refresh token allowance
  useEffect(() => {
    getTokenAllowance()
  }, [chainId, sourceSym, address])

  return (
    <Flex
      my={3}
      fontSize={"13px"}
      height="full"
      flexDir={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      backgroundColor={overallBgColor}
    >
      <Flex flexDir={'column'} width={{base: "90%", md: "50%", lg: "45%"}} p={4} bgColor={convertBoxBgColor} rounded={"lg"} gap={4}>
        <OrderTypeSwitch 
          setOrderType={setOrderType}
          />
        <SourceSym
          orderType={orderType}
          sourceQuantity={sourceQuantity} 
          setSourceQuantity={setSourceQuantity}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isLockedOrder={isLockedOrder}
          hasEditedPrice={hasEditedPrice}
          setHasExceedNetBal={setHasExceedNetBal}
          debouncedGetBridgingRate={debouncedGetBridgingRate}
          />
        <Box alignSelf={'center'}>
          <Box
            p={2}
            height='full'
            bgColor={swapBtnBgColor}
            rounded="lg"
            onClick={async() => {!isLockedOrder? await swapDirection() : null}}
            >
            <IconButton  
              variant={"ghost"} 
              width="full"
              height="full"
              isDisabled={isLockedOrder}
              aria-label="swap button 2" 
              icon={<AiOutlineSwap style={{transform: "rotate(90deg)"}}/>}
              _hover={{transform:'rotate(180deg)'}} 
            />
          </Box>
        </Box>
        <DestSym
          orderType={orderType}
          destQuantity={destQuantity}
          setDestQuantity={setDestQuantity}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isLockedOrder={isLockedOrder}
          hasEditedPrice={hasEditedPrice}
          debouncedGetBridgingRate={debouncedGetBridgingRate}
          />
        {sourceChain.chainId !== 0 && Number(bridgingRate) > 0 && Number(sourceQuantity) > 0 &&
          <Flex flexDir={'column'}>
            <Fade in={sourceChain.chainId !== 0 && Number(bridgingRate) > 0 && Number(sourceQuantity) > 0} style={{transitionDuration: "0.1s"}}>
              <RateBox 
                orderType={orderType}
                slippagePercent={slippagePercent}
                setSlippagePercent={setSlippagePercent}
                isLockedOrder={isLockedOrder}
                setIsEditing={setIsEditing}
                setHasEditedPrice={setHasEditedPrice}
                debouncedGetBridgingRate={debouncedGetBridgingRate}
                />
              <FeesBox 
                orderType={orderType}
                destQuantity={destQuantity}
                hasClientSideErrorMsg={isSwapAmtTooSmall || hasExceedNetBal}
              />
              <ExtraSettings
                destChain={destChain}
                withdrawalAddress={withdrawalAddress}
                setWithdrawalAddress={setWithdrawalAddress}
              />
            </Fade>
          </Flex>
        }
        {serverSideErrorMsg
          ? <HStack bgColor={errorBoxBgColor} rounded="md" p={2}>
              <IoAlertCircleSharp fontSize={'xs'}/>
              <Text>{serverSideErrorMsg}</Text>
            </HStack>
          : <></>
        }
        {hasExceedNetBal || isSwapAmtTooSmall || isFeeExceedDestQty
          ? <HStack bgColor={errorBoxBgColor} rounded="md" p={2}>
            <IoAlertCircleSharp fontSize={'xs'}/>
            <Text>{
              hasExceedNetBal
              ? "Exceeded available balance"
              : isSwapAmtTooSmall
                ? `Trading size below minimum requirement:
                ${bridgingDirectionDisplayId === 0? sourceSym.minTradingSize + sourceSym.symbol : destSym.minTradingSize + destSym.symbol}`
                : isFeeExceedDestQty
                  ? "Gas price is currently too high. Try swapping later or swap a bigger amount to cover the gas fee."
                  : ""
              }
            </Text>
          </HStack>
          : <></>
        }
        {Number(bridgingRate) > 0
          ? 
          <Flex flexDir={'column'} gap={2} width='full'>
            <Button 
              isDisabled={
                serverSideErrorMsg || 
                isSwapAmtTooSmall ||
                hasExceedNetBal ||
                isFeeExceedDestQty ||
                Number(bridgingRate) <= 0 ||
                Number(destQuantity) <= 0 ||
                destChain.chainType.name !== "evm" && !withdrawalAddress 
                ? true : false}
              colorScheme={"telegram"}
              fontSize={'13px'}
              onClick={onOpen}
              isLoading={isSubmittingOrder}
              >
              Submit
            </Button>
            <Button
              fontSize={'13px'}
              onClick={handleReset}
              >
              Cancel
            </Button>
          </Flex>
          :
          <>
            <Button 
              isDisabled={
                serverSideErrorMsg || 
                isSwapAmtTooSmall ||
                hasExceedNetBal ||
                !isEditing ||
                isEditing === 'sourceQty' && Number(sourceQuantity) <= 0 ||
                isEditing === 'destQty' && Number(destQuantity) <= 0 ||
                sourceChain.chainId === destChain.chainId && sourceSym.symbol === destSym.symbol
                ? true : false
              }
              colorScheme="whatsapp"
              fontSize={'13px'} 
              onClick={handleGetPreviewRate}> 
              Preview Conversion 
            </Button>
            <Button
              fontSize={'13px'}
              onClick={handleReset}
              >
              Reset
            </Button>
          </>
          }
      </Flex>
      <OneClickModal 
        isOpen={isOpen}
        onClose={onClose}
        depositAmount={sourceQuantity}
        sourceQuantity={sourceQuantity}
        destQuantity={destQuantity}
        orderType={orderType}
        hasEditedPrice={hasEditedPrice}
        hasExpiry={hasExpiry}
        expiryDate={expiryDate}
        withdrawalAddress={withdrawalAddress}
        handleReset={handleReset}
        setIsSubmittingOrder={setIsSubmittingOrder}
        setIsLockedOrder={setIsLockedOrder}
        />
    </Flex>
  );
}


import { Avatar, Flex, HStack, SimpleGrid, Text, VStack, useColorMode, useColorModeValue } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"
import { selectMarketByChain } from "../../features/mesMarketSlice"
import { defaultL2Chain, defaultMarket, defaultETH } from "../../constants"
import React, { Dispatch, SetStateAction, useState } from "react"
import { selectSourceChain, setSourceSym, setSourceChain, selectSourceSym, selectDestChain, selectDestSym, setDestChain, setDestSym } from "../../features/mesConvertSlice"
import { pickBgColorByChainId, pickColorByChainId } from "../../utils/colorPicker"
import { useLocation } from "react-router-dom"

export default function TokenPicker({
  direction,
  onClose
} : {
  direction: "source" | "dest",
  onClose: () => void
}){
  const marketByChain = useSelector(selectMarketByChain);
  const chain = useSelector(direction === "source"? selectSourceChain : selectDestChain);
  const [selectedChain, setSelectedChain] = useState(chain);
  const [selectedTokenList, setSelectedTokenList] = useState(() => {
    const market = marketByChain.find((m) => m.chain.chainId === chain.chainId)
    if(market) return market.tokenList
    else return
  })

  return(
    <SimpleGrid columns={2} spacing={5}>
      <VStack>
        {marketByChain.map(((market, i) => (
          market.tokenList.length > 0 &&
            <ChainOption 
              key={i}
              market={market}
              selectedChain={selectedChain}
              setSelectedChain={setSelectedChain}
              setSelectedTokenList={setSelectedTokenList}
              />
        )
        ))}
      </VStack>
      <TokenOption
        direction={direction}
        selectedChain={selectedChain}
        selectedTokenList={selectedTokenList}
        onClose={onClose}
        />
    </SimpleGrid>
  )
}

const ChainOption = React.memo(({
  market,
  selectedChain,
  setSelectedChain,
  setSelectedTokenList
} : {
  market: typeof defaultMarket,
  selectedChain: typeof defaultL2Chain
  setSelectedChain: Dispatch<SetStateAction<typeof defaultL2Chain>>,
  setSelectedTokenList: Dispatch<SetStateAction<typeof defaultMarket.tokenList | undefined>>
}) => {
  const chain = market.chain;
  const { colorMode } = useColorMode();
  const hoverColor = useColorModeValue("gray.100", "gray.600");
  const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
  const chainColor = pickColorByChainId(colorMode, chain.chainId);
  const chainBgColor = pickBgColorByChainId(colorMode, chain.chainId);

  return(
    <Flex 
      py={2}
      width={'full'}
      rounded={"md"}
      onClick={() => {
        setSelectedChain(chain)
        setSelectedTokenList(market.tokenList)
      }}
      cursor={'pointer'}
      flexDir={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      border={"1px"}
      borderColor={selectedChain.chainId === chain.chainId? chainColor : hoverColor}
      bgColor={selectedChain.chainId === chain.chainId? chainBgColor: undefined}
      _hover={{ bgColor: selectedChain.chainId === chain.chainId? undefined: hoverColor}}
      >
      <HStack px={2} spacing={4} justifyItems={'space-between'}>
        <Avatar bg={avatarBgColor} size={{base: "xs", sm:'sm'}} name={chain.name} src={useColorModeValue(chain.lightLogo, chain.darkLogo)}/>
        <Text as={'b'} fontSize={{base: "xs", sm:'sm'}}>{chain.name}</Text>
      </HStack>
    </Flex>
  )
})

const TokenOption = React.memo(({
  direction,
  selectedChain,
  selectedTokenList,
  onClose
} : {
  direction: "source" | "dest",
  selectedTokenList: typeof defaultMarket.tokenList | undefined
  selectedChain: typeof defaultL2Chain
  onClose: () => void
}) => {
  const chain = useSelector(direction === "source"? selectSourceChain : selectDestChain);
  const sym = useSelector(direction === "source"? selectSourceSym : selectDestSym);
  const hoverColor = useColorModeValue("gray.100", "gray.600");
  const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
  const dispatch = useDispatch();
  const currentPath = useLocation();
  let sortedTokenList = selectedTokenList 
  ? [...selectedTokenList].filter((token) => direction === "source" ? !token.sourceDisabled : !token.destDisabled).sort(function(a,b) {
    if(a.id < b.id) return -1
    else return 1
  })
  : []

  function handleTokenChange(token: typeof defaultETH){
    if(direction === "source"){
      const pathName = currentPath.pathname.split("/")
      dispatch(setSourceChain(selectedChain))
      dispatch(setSourceSym(token))
      //shallow update path
      window.history.replaceState(null, "", `/convert/${selectedChain.chainId}-${token.symbol}/${pathName[3]}`)
    } else {
      const pathName = currentPath.pathname.split("/")
      dispatch(setDestChain(selectedChain))
      dispatch(setDestSym(token))
      //shallow update path
      window.history.replaceState(null, "", `/convert/${pathName[2]}/${selectedChain.chainId}-${token.symbol}`)
    }
    onClose();
  }

  return(
    <VStack>
      {sortedTokenList.map((token, i) => (
        <Flex 
          key={i}
          py={2} 
          px={2}
          width={'full'}
          rounded={"md"}
          onClick={() => handleTokenChange(token)}
          cursor={'pointer'}
          bgColor={selectedChain.chainId === chain.chainId && token.symbol === sym.symbol ? hoverColor : undefined}
          _hover={{ bgColor: hoverColor}}
          flexDir={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          >
          <HStack spacing={4}>
            <Avatar bg={avatarBgColor} size={{base: "xs", sm:'sm'}} name={token.name} src={token.logoUrl}/>
            <Flex flexDir={'column'}>
              <Text as={'b'}>{token.symbol}</Text>
              <Text as={'b'} fontSize={{base: "xs", sm:'11px'}} color="gray.500">{token.name}</Text>
            </Flex>
          </HStack>
        </Flex>
      ))}
    </VStack>
  )
})
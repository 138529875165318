import { combineReducers, configureStore, PreloadedState } from "@reduxjs/toolkit";
import userOrderReducer from './features/userOrderSlice'
import mpStaticReducer from './features/mpStaticSlice'
import mesMarketReducer from './features/mesMarketSlice'
import userPortfolioReducer from './features/userPortfolioSlice'
import mesConvertReducer from './features/mesConvertSlice'
import userReceiptsReducer from './features/userReceiptsSlice'
import userHookReducer from './features/userHookSlice'

const rootReducer = combineReducers({
  userOrder: userOrderReducer,
  userHook: userHookReducer,
  mpStatic: mpStaticReducer,
  mesMarkets: mesMarketReducer,
  userPortfolio: userPortfolioReducer,
  userReceipts: userReceiptsReducer,
  mesConvert: mesConvertReducer
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

const store = configureStore({
  reducer: rootReducer
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export default store

import { useEffect, useState } from "react";
import { PieChart, Pie, Cell, LabelList } from "recharts";
import { BalanceByChainProps, BalanceBySymProps } from "../../types";
import { generateRandColorArr } from "../../utils/colorPicker";
import { useColorMode } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectBalanceByChain, selectBalanceBySym } from "../../features/userPortfolioSlice";

const BalanceChart = ({displayChain}: {displayChain:string}) => {
  const [chartData, setChartData] = useState<{name:string, value:number}[]>([])
  const balanceByChain : BalanceByChainProps = useSelector(selectBalanceByChain)
  const balanceBySym : BalanceBySymProps = useSelector(selectBalanceBySym)
  const colorModeContext = useColorMode()
  useEffect(() => {
    if(displayChain === 'Cross'){
      const processData = Object.keys(balanceBySym)?.map((sym) => {
        return {
          name: sym,
          value: balanceBySym[sym].assetValue
        }
      })
      setChartData(processData)
    } else {
      const processData = balanceByChain[displayChain]?.map((balance) => {
        return {
          name: balance.symbol,
          value: balance.assetValue
        }
      })
      setChartData(processData)
    }
  }, [displayChain, balanceBySym, balanceByChain])
  const COLORS = generateRandColorArr(Object.keys(balanceBySym).length, colorModeContext.colorMode)
  const renderLabel = (entry:any) => {
    if(entry.value > 0) return entry.name
  }
  return (
    <PieChart width={300} height={250}>
      <Pie
        data={chartData}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        innerRadius={60}
        outerRadius={80}
        label={renderLabel}
        labelLine={false}
        paddingAngle = {10}
      >
      {Object.keys(balanceBySym).map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default BalanceChart;

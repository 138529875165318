import { createRoot } from "react-dom/client";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./store";
import { Global, css } from "@emotion/react";
import "focus-visible/dist/focus-visible";
import { disableReactDevTools } from "./disableReactDevTool.js";
import {theme} from "./theme";

const container = document.getElementById("app");
const root = createRoot(container!);
const GlobalStyles = css`
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;
disableReactDevTools();

root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <Global styles={GlobalStyles} />
      <App />
    </ChakraProvider>
  </Provider>
);

import { createContext, useContext, useEffect, useMemo } from "react";
import { io, Socket } from "socket.io-client";
import { toast } from "react-toastify";
import {
  getUserOrder,
  getUserPortfolio,
  getMesMarkets,
  getUserReceipts,
  getUserHooks,
} from "../helpers/wssHelpers";
import {
  ServerToClientEvents,
  ClientToServerEvents,
  WsContextProps,
} from "../types";
import { useDispatch } from "react-redux";
import { createUserOrder } from "../features/userOrderSlice";
import { setTotalAssetValue, setUserPortfolio } from "../features/userPortfolioSlice";
import { setSingleConvertData } from "../features/mesConvertSlice";
import { setCurrentNonce } from "../features/mpStaticSlice";
import { AppDispatch } from "../store";
import { backendPrefix } from "../utils/backendPrefix";
import { ethers } from "ethers";
import { setMesMarketByChain } from "../features/mesMarketSlice";
import { setUserReceipts } from "../features/userReceiptsSlice";
import { setUserHook } from "../features/userHookSlice";
import { useAccount } from "wagmi";

const options = {
  transports: ["websocket"],
  cors: {
    origin: "*",
  },
};
const initialValue ={
  socket: io(backendPrefix, options),
};

export const WsContext = createContext<WsContextProps>(initialValue);

export const WsProvider = ({ children }: any) => {
  const { address } = useAccount();
  const dispatch = useDispatch<AppDispatch>();
  //instantiate webSocket instances
  const socket: Socket<ServerToClientEvents, ClientToServerEvents> =
    useMemo(() => {
      return io(backendPrefix, options);
    }, []);

  //handle acccount switch
  useEffect(() => {
    if (!address) return
    const ethersAddress = ethers.utils.getAddress(address)
    getUserPortfolio(ethersAddress, socket);
    getUserReceipts(ethersAddress, socket);
    getUserOrder(ethersAddress, socket);
    getUserHooks(ethersAddress, socket);
    registeringListeners()
  }, [address]);

  //get mes market
  useEffect(() => {
    getMesMarkets(socket)
  }, [])

  const registeringListeners = () => {
    //removing existing listeners for the old account
    socket.removeAllListeners();
    //registering new listeners for the new account
    socket.on('userOrder', onUserOrder);
    socket.on('userHook', onUserHook);
    socket.on('lastNonce', onLastNonce);
    socket.on('userPortfolio', onUserPortfolio);
    socket.on('userReceipts', onUserReceipts);
    socket.on('refreshUserOrder', onRefreshUserOrder);
    socket.on('refreshUserHook', onRefreshUserHook);
    socket.on('refreshUserPortfolio', onRefreshUserPortfolio);
    socket.on('refreshUserReceipts', onRefreshUserReceipts);
    socket.on('cancelOrder', onCancelOrder);
    socket.on('cancelAllUserOrders', onCancelOrder);
    socket.on('mesMarkets', onMesMarkets);
    socket.on('bridgingRate', onBridgingRate);
  }

  //methods
  
  //get bridging rate
  const onBridgingRate = (response:any) => {
    const wsResponse = JSON.parse(response)
    if(wsResponse?.op === 'success'){
      dispatch(setSingleConvertData(wsResponse?.args))
    } else {
      //reset bridging rate to 0
      dispatch(setSingleConvertData(wsResponse?.args))
    }
  }

  //get user last nonce
  const onLastNonce = (response:any) => {
    const wsResponse = JSON.parse(response);
    const lastNonce = wsResponse?.args?.lastNonce
    if(lastNonce >= -1){
      dispatch(setCurrentNonce(lastNonce))
    }
  }

  //get mes markets
  const onMesMarkets = (response: any) => {
    const wsResponse = JSON.parse(response);
    dispatch(setMesMarketByChain(wsResponse.args))
  }

  //get user order
  const onRefreshUserOrder = () => {
    if(!address) return
    getUserOrder(address, socket);
  }

  //get user hooks
  const onRefreshUserHook = () => {
    if(!address) return
    getUserHooks(address, socket);
  }

  //get user portfolio
  const onRefreshUserPortfolio = () => {
    if(!address) return
    getUserPortfolio(address, socket)
  }
  //get user portfolio
  const onRefreshUserReceipts = () => {
    if(!address) return
    getUserReceipts(address, socket)
  }

  //receive user order
  const onUserOrder = (response:any) => {
    const wsResponse = JSON.parse(response);
    dispatch(createUserOrder(wsResponse.args));
  }
  const onUserHook = (response:any) => {
    const wsResponse = JSON.parse(response);
    dispatch(setUserHook(wsResponse.args));
  }

  //receive user portfolio
  const onUserPortfolio = (response:any) => {
    const wsResponse = JSON.parse(response);
    dispatch(setUserPortfolio(wsResponse.args))
    dispatch(setTotalAssetValue(wsResponse.args?.totalAssetValue))
  }

  //receive user receipts
  const onUserReceipts = (response:any) => {
    const wsResponse = JSON.parse(response);
    dispatch(setUserReceipts(wsResponse.args))
  }

  //cancel order
  const onCancelOrder = (response:any) => {
    const wsResponse = JSON.parse(response);
    const op = wsResponse.op;
    const args = wsResponse.args;
    if (op === "success") {
      toast(`Order cancelled!`, {
        type: "success",
        theme: "dark",
        position: "top-right",
        toastId: "orderCancelled",
      });
      //get latest user order and user balance
      getUserOrder(args.userAddress, socket)
      getUserPortfolio(args.userAddress, socket)
    } else {
      toast(`Failed to cancel order! ${args}`, {
        type: "error",
        theme: "dark",
        position: "top-right",
        toastId: "orderNotCancelled",
      });
    }
  }

  // ------ One time Listeners ------
  socket.once("connect", () => {
    console.log("Connected to Mes Protocol WS");
  });
  socket.on('mesMarkets', onMesMarkets);

  return (
    <WsContext.Provider
      value={{socket}}
    >
      {children}
    </WsContext.Provider>
  );
};

export function useWebsocket() {
  const context = useContext(WsContext);
  if (context == undefined) {
    throw new Error("useWebsocket must be used within a provider");
  }
  return context;
}

import { Box, Divider, Flex, HStack, Image, Text, useColorModeValue } from "@chakra-ui/react";
import * as Router from "react-router-dom";

export default function MesLogo() {
  return(
    <Flex
      h={16}
      alignItems='center'
      justifyContent={'space-between'}
      >
      <HStack spacing={10}>
        <HStack justifyContent={'flex-start'}>
          <Router.Link
            to={"/"}
            > 
            <Image 
              src= {useColorModeValue('/long_logo_blue.png', '/long_logo_white.png')} 
              width={{base:'120px', md: '180px'}}
              ml='10px' 
              alt='logo'
              cursor='pointer'
              />
          </Router.Link>
          <Box border={'2px'} rounded={'md'} px={'1'} color={useColorModeValue("cyan.800", "cyan.400")}>
            <Text 
              fontSize={{base: 8, md: 12}} 
              fontWeight="bold"
              >MAINNET</Text>
          </Box>
        </HStack>
        <Divider display={{base: "none", sm: "flex"}} height="30px" orientation="vertical"/>
      </HStack>
    </Flex>
  )
}

import { ChevronRightIcon, InfoIcon } from "@chakra-ui/icons";
import { Flex, Text, Avatar, useColorModeValue, HStack, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectDestChain, selectDestSym, selectFeeData, selectSingleIsMultiRoute, selectSingleRoutingPath, selectSingleServerSideErrorMsg, selectSourceChain } from "../../features/mesConvertSlice";

export default function FeesBox({
  orderType,
  destQuantity,
  hasClientSideErrorMsg
}: {
  orderType: string,
  destQuantity: string,
  hasClientSideErrorMsg: boolean
}){
  const [postFeeQty, setPostFeeQty] = useState<string>();
  const [totalFees, setTotalFees] = useState<string>();
  const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
  const tooltipBgColor = useColorModeValue("gray.500", "gray.100")
  const serverSideErrorMsg = useSelector(selectSingleServerSideErrorMsg);
  const isMultiRoute = useSelector(selectSingleIsMultiRoute);
  const routingPath = useSelector(selectSingleRoutingPath);
  const feeData = useSelector(selectFeeData);
  const sourceChain = useSelector(selectSourceChain);
  const destChain = useSelector(selectDestChain);
  const destSym = useSelector(selectDestSym);

  useEffect(() => {
    const totalFees = Number(feeData.tradingFees) + Number(feeData.sourceChainFeeData) + Number(feeData.destChainFeeData)
    setTotalFees(totalFees.toFixed(5))
    setPostFeeQty((Number(destQuantity) - totalFees).toFixed(destSym.displayDecimal))
  }, [destSym, destQuantity])

  const FeeInfo = () => {
    return(
      <Flex flexDir={'column'} p={4} alignItems='flex-start' color={useColorModeValue("black", "white")}>
        <Text fontWeight={'extrabold'} fontSize={"xs"}> Fee Breakdown : </Text>
        <Text fontSize={"xs"} mt={2}> {`● Bonder Fee: ${Number((Number(feeData.sourceChainFeeData) + Number(feeData.destChainFeeData)).toFixed(5))} ${destSym.symbol}`}</Text>
        <Text fontSize={"xs"}> {`● Trading Fee: ${Number(feeData.tradingFees.toFixed(5))} ${destSym.symbol}`}</Text>
        <Text as={"i"} fontSize={"xs"}> * Bonder fees are fees paid to bonders for moving tokens across chains.</Text>
      </Flex>
    )
  }

  const RouteInfo = () => {
    return(
      <Flex flexDir={'column'} p={4} alignItems='flex-start'>
        <Text fontWeight={'extrabold'} fontSize={"xs"}> The order route might vary based on the trading pair & chain you selected.</Text>
        {orderType === 'Limit' 
        ? <>
            <Text fontSize={"xs"}> Please note that limit orders will be split into multiple conditional orders * .</Text>
            <Text as={"i"} fontSize={'xs'}> * Orders that will only be triggered when the previous order on the same route is being settled.</Text>
          </>
        : <></>
        }
      </Flex>
    )
  }

  const RoutingPath = ({index, displayPath} : {index:number, displayPath:any}) => {
    let sourceLogoPath: string = ""
    let destLogoPath: string = ""
    if(displayPath?.directionDisplayId === 0){
      //buy order, source sym is quote and dest sym is base
      sourceLogoPath = displayPath?.assetPair?.quoteAsset?.logoUrl
      destLogoPath = displayPath?.assetPair?.baseAsset?.logoUrl
    } else {
      //sell order, source sym is base and dest sym is quote
      sourceLogoPath = displayPath?.assetPair?.baseAsset?.logoUrl
      destLogoPath = displayPath?.assetPair?.quoteAsset?.logoUrl
    }
    return(
      <Flex flexDir={'row'} alignItems="center" gap={0.5}>
        <Avatar bg={avatarBgColor} size={'2xs'} name={displayPath?.sourceSym} src={sourceLogoPath}/>
        <Text as={'b'} whiteSpace="nowrap">{displayPath?.sourceSym} on </Text>
        <Avatar bg={avatarBgColor} size={'xs'} name={sourceChain?.name} src={useColorModeValue(sourceChain?.lightLogo, sourceChain?.darkLogo)}/>
        <ChevronRightIcon/>
        {index === routingPath.length - 1
        ?<>
          <Avatar bg={avatarBgColor} size={'2xs'} name={displayPath?.destSym} src={destLogoPath}/>
          <Text as={'b'} whiteSpace="nowrap">{displayPath?.destSym} on </Text>
          <Avatar bg={avatarBgColor} size={'xs'} name={destChain?.name} src={useColorModeValue(destChain?.lightLogo, destChain?.darkLogo)}/>
        </>
        :<></>
        }
      </Flex>
    )
  }

  return(
    <Flex flexDir={'column'} p={2} gap={1} fontSize={{base: "11px", lg: "13px"}}>
      {isMultiRoute
      ? <Flex flexDir={'row'} justifyContent='space-between' alignItems={'center'}>
          <HStack>
            <Text> Route: </Text>
            <Tooltip label={<RouteInfo/>} placement='bottom' bg={avatarBgColor}>
              <InfoIcon color={tooltipBgColor}/>
            </Tooltip>
          </HStack>
          <HStack fontSize={'11px'} overflowX="auto" ml={4}>
            {routingPath?.map((displayPath, i) => {return <RoutingPath key={i} index={i} displayPath={displayPath}/>})}
          </HStack>
        </Flex>
      : <></>
      }
      <Flex flexDir={'row'} justifyContent='space-between' alignItems={'center'}>
        <HStack>
          <Text> Fees: </Text>
          <Tooltip label={<FeeInfo/>} placement='bottom' bg={avatarBgColor}>
            <InfoIcon color={tooltipBgColor}/>
          </Tooltip>
        </HStack>
        <HStack>
          <Text> {serverSideErrorMsg || hasClientSideErrorMsg ? "--" : `~ ${totalFees}`} </Text>
          <Avatar bg={avatarBgColor} size={'xs'} name={destSym.name} src={destSym.logoUrl}/>
          <Text as={'b'}>{destSym.symbol}</Text>
        </HStack>
      </Flex>
      <HStack justifyContent={'space-between'}>
        <Text fontWeight={'extrabold'}>Final Receivable {orderType === 'Market'? " (Estimate):" : ":"}</Text>
        <HStack>
          <Text fontWeight={'extrabold'}>{serverSideErrorMsg || hasClientSideErrorMsg || Number(postFeeQty) < 0 ? "--" :`~ ${postFeeQty}`}</Text>
          <Avatar bg={avatarBgColor} size={'xs'} name={destSym.name} src={destSym.logoUrl}/>
          <Text as={'b'}>{destSym.symbol}</Text>
        </HStack>
      </HStack>
    </Flex>
  )
}
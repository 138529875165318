import {
  InputGroup,
  Input,
  InputRightElement,
  Button,
  useColorModeValue,
  Text,
  Flex,
  HStack,
  Switch,
  Collapse,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { IoAlertCircleSharp } from "react-icons/io5";
import { countDecimals, roundDownTo } from "../../utils/numbers";

export default function WithdrawInputBox({
  symbol,
  withdrawableNetBal,
  withdrawAmount,
  setWithdrawAmount,
  withdrawalAddress,
  setWithdrawalAddress,
  isAdvanceModeEnabled,
  setIsAdvanceModeEnabled,
  nextStep,
  signWithdrawRequest
}: {
  symbol: string
  withdrawableNetBal: string
  withdrawAmount: string
  setWithdrawAmount: Dispatch<SetStateAction<string>>
  withdrawalAddress: string
  setWithdrawalAddress: Dispatch<SetStateAction<string>>
  isAdvanceModeEnabled: boolean
  setIsAdvanceModeEnabled: Dispatch<SetStateAction<boolean>>
  nextStep: () => void
  signWithdrawRequest: () => void
}){
  const [isWithdrawalAddressValid, setIsWithdrawalAddressValid] = useState<boolean>(true)
  const errorBoxBgColor = useColorModeValue("red.200","red.700");
  
  function handleWithdrawAmountChange(input:string){
    if(countDecimals(input) <= 8){
      setWithdrawAmount(input.replace(/^0+(?=\d)/, ''))
    }
  }

  function handleWithdrawalAddressChange(input:string){
    if(input){
      if(input.length !== 42 || input.slice(0,2) !== "0x"){
        setIsWithdrawalAddressValid(false)
      } else {
        setIsWithdrawalAddressValid(true)
      }
    } else {
      setIsWithdrawalAddressValid(true)
    }
    setWithdrawalAddress(input)
  }

  function handleSubmit(){
    nextStep()
    signWithdrawRequest()
  }
  
  return (
    <Flex flexDir="column" fontSize='14px' p={3} width={"100%"} gap={1}>
      <Text> Input withdraw amount: </Text>
      <InputGroup mt={5} size="md">
        <Input
          fontSize={{base:'12px', xl:"14px"}}
          placeholder="Withdraw from vault.."
          type="number"
          value={withdrawAmount}
          onWheel={(e) => e.currentTarget.blur()}
          onChange={(e) => {
            handleWithdrawAmountChange(e.target.value)
          }}
        />
        <InputRightElement width="4.5rem">
          <Button
            size="sm"
            fontSize={{base:'10px', xl:"12px"}}
            onClick={() => {
              const roundedWithdrawAmount = roundDownTo(Number(withdrawableNetBal),8)
              setWithdrawAmount(roundedWithdrawAmount.toString())
            }}
          >
            Max
          </Button>
        </InputRightElement>
      </InputGroup>
      <Text
        fontSize={{base:'12px', xl:"14px"}}
        color={useColorModeValue("gray.800", "gray.400")}
        textAlign={"right"}
      >
        Max: {" "}
        <b>
          {isNaN(Number(withdrawableNetBal)) || withdrawableNetBal === "-"
            ? "-"
            : roundDownTo(Math.max(0, Number(withdrawableNetBal)),8)
            }
        </b>
        {" "} {symbol}
      </Text>
      <Flex flexDir={"row"} justifyContent={"space-between"}>
        <Text fontSize={"13px"}>Advanced Configuration</Text>
        <Switch onChange={() => setIsAdvanceModeEnabled(!isAdvanceModeEnabled)}/>
      </Flex>
      <Collapse in={isAdvanceModeEnabled}>
        <Flex flexDir={"column"} fontSize='13px' mt={2} width={"100%"} gap={1}>
          <Text as={"b"}>{`Set recipient's address:`}</Text>
          <Input
            fontSize={{base:'12px', xl:"13px"}}
            placeholder="Input a withdrawal address"
            type="text"
            value={withdrawalAddress}
            onChange={(e) => {
              handleWithdrawalAddressChange(e.target.value)
            }}
          />
          <Text as={"i"} size={"xs"}>{`* Double check the recipient's address as any tokens sent to a wrong address cannot be recovered.`}</Text>
        </Flex>
        {!isWithdrawalAddressValid &&
          <HStack border="1px" borderColor={errorBoxBgColor} rounded="md" p={2} mt={3}>
            <IoAlertCircleSharp fontSize={'xs'}/>
            <Text>{"Invalid Address"}
            </Text>
          </HStack>
        }
      </Collapse>
      {Number(withdrawAmount) > Number(withdrawableNetBal)
        ? <HStack border="1px" borderColor={errorBoxBgColor} rounded="md" p={2} mt={3}>
          <IoAlertCircleSharp fontSize={'xs'}/>
          <Text>{"Exceeded available balance"}
          </Text>
        </HStack>
        : <></>
      }
      <Button
        mt={2}
        width={"full"}
        fontSize={{ base: "12px", xl: "14px" }}
        colorScheme="messenger"
        onClick={handleSubmit}
        isDisabled={
          Number(withdrawAmount) > Number(withdrawableNetBal) ||
          Number(withdrawAmount) <= 0 ||
          isAdvanceModeEnabled && !isWithdrawalAddressValid
        }
      >
        {"Withdraw " + symbol}
      </Button>
    </Flex>
  );
}

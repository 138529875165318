import { Collapse, Text, Flex, Input, Switch, HStack, useColorModeValue } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useState } from "react"
import { IoAlertCircleSharp } from "react-icons/io5"
import { defaultNullChain } from "../../constants"

export default function ExtraSettings({
  destChain,
  withdrawalAddress,
  setWithdrawalAddress,
} : {
  destChain: typeof defaultNullChain
  withdrawalAddress: string | undefined
  setWithdrawalAddress: Dispatch<SetStateAction<string | undefined>>
}){
  const [isShowSetting, setIsShowSetting] = useState<boolean>(() => {
    if(destChain.chainType.name !== "evm"){
      return true
    } else return false
  })
  const [isWithdrawalAddressValid, setIsWithdrawalAddressValid] = useState<boolean>(true)
  const errorBoxBgColor = useColorModeValue("red.200","red.700");
  const bgColor = useColorModeValue("gray.50", "gray.700")

  function handleWithdrawalAddressChange(input:string){
    setWithdrawalAddress(input)
    if(input && destChain.chainType.name == "evm"){
      if(input.length !== 42 || input.slice(0,2) !== "0x"){
        setIsWithdrawalAddressValid(false)
        return
      }
    } else if(input && destChain.chainType.name == "dydx"){
      if(input.slice(0,4) !== "dydx"){
        setIsWithdrawalAddressValid(false)
        return
      }
    }
    setIsWithdrawalAddressValid(true)
  }
  return(
    <>
      <Flex flexDir={"row"} p={2} justifyContent={"space-between"}>
        <Text fontSize={"13px"}>Advanced Setting</Text>
        <Switch isChecked={isShowSetting} onChange={() => setIsShowSetting(!isShowSetting)}/>
      </Flex>
      <Collapse in={isShowSetting}>
        <Flex flexDir={"column"} gap={4} bgColor={bgColor} p={2} rounded={"lg"}>
          <Flex flexDir={"column"} fontSize='13px' mt={2} width={"100%"} gap={1}>
            <Text as={"b"}>{`Set recipient's address:`}</Text>
            <Input
              fontSize={{base:'12px', xl:"13px"}}
              placeholder="Input a target address"
              type="text"
              required={destChain.chainType.name !== "evm"}
              value={withdrawalAddress}
              onChange={(e) => {
                handleWithdrawalAddressChange(e.target.value)
              }}
            />
            <Text as={"i"} size={"xs"}>{`* Double check the recipient's address as any tokens sent to a wrong address cannot be recovered.`}</Text>
          </Flex>
          {!isWithdrawalAddressValid &&
            <HStack border="1px" borderColor={errorBoxBgColor} rounded="md" p={2} mt={3}>
              <IoAlertCircleSharp fontSize={'xs'}/>
              <Text>{"Invalid Address"}
              </Text>
            </HStack>
          }
        </Flex>
      </Collapse>
    </>
  )
}
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Link, Select, Icon, Text, Popover, PopoverTrigger, PopoverContent, useColorMode, useColorModeValue, HStack, Switch } from "@chakra-ui/react"
import { FiSettings } from 'react-icons/fi'
import { BsSun, BsMoon } from 'react-icons/bs'
import { useState } from "react";

const Settings = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const [language, setLanguage] = useState('English')
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('cyan.700', 'cyan.400');
  return(
    <Box p={2}>
      <Popover trigger={'hover'} placement={'bottom-start'}>
        <PopoverTrigger>
          <Link
            pr={{base: 0, sm: 2.5, md: 5}}
            role={'group'}
            color={linkColor}
            _hover={{
              color: linkHoverColor,
            }}>
            <Icon 
              mt={'5px'}
              w={'auto'}
              as={FiSettings} 
              transition={'all .25s ease-in-out'}
              _groupHover={{ transform: 'rotate(180deg)' }}
            />
          </Link>
        </PopoverTrigger>

        <PopoverContent
          border={0}
          boxShadow={'xl'}
          p={4}
          rounded={'xl'}
          >
            <HStack justifyContent={'space-between'}>
              <Text fontSize={"13px"}> Language </Text>
              <Select
                w="140px"
                fontSize={"13px"}
                defaultValue={language}
                icon={<ChevronDownIcon />}
                >
                  <option value="English">English</option>
                </Select>
            </HStack>
            <HStack mt={4} justifyContent={'space-between'}>
              <Text fontSize={"13px"}> Theme</Text>
              <HStack>
                <BsSun/>
                <Switch onChange={toggleColorMode} isChecked={colorMode === 'dark'? true : false}></Switch>
                <BsMoon/>
              </HStack>
            </HStack>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export default Settings
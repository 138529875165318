import './App.css';
import { ToastContainer } from 'react-toastify';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import NotFound from './pages/NotFound';
import 'react-toastify/dist/ReactToastify.css';
import Convert from './pages/Convert';
import Portfolio from './pages/Portfolio';
import { defaultL1Chain, defaultL2Chain } from './constants';
import { WsProvider } from './context/useWebsocket';
import { Web3Modal } from './utils/wallet';

function App() {
  return (
    <Web3Modal>
      <WsProvider>
        <ToastContainer
          position="top-right"
          autoClose={6000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          draggable
        />
        <Router>
          <Routes>
            <Route path = '/' element={<Navigate to= {`/convert/${defaultL1Chain.chainId}-USDC/${defaultL2Chain.chainId}-USDC`} replace={true}/>}/>
            <Route path = '/convert' element={<Navigate to= {`/convert/${defaultL1Chain.chainId}-USDC/${defaultL2Chain.chainId}-USDC`} replace={true}/>}/>
            <Route path = '/convert/:source/:dest' element = {<Convert/>}/>
            <Route path = '/portfolio' element = {<Portfolio/>} />
            <Route path = '/transfers' element={<Portfolio/>}/>
            <Route path = '/earn' element={<Portfolio/>}/>
            <Route path = '*' element = {<NotFound/>}/>
          </Routes>
        </Router>
      </WsProvider>
    </Web3Modal>
  );
}

export default App;

import {
  Flex,
  Text,
  Divider,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import React from "react";
import { useEffect, useState } from "react";
import { BalanceByChainProps, BalanceBySymProps, PortfolioBalanceProps } from "../../types";
import RecordNotFound from "../RecordNotFound";
import ActionButton from "./ActionButton";
import { useSelector } from "react-redux";
import { selectBalanceByChain, selectBalanceBySym } from "../../features/userPortfolioSlice";

const UserBalances = ({displayChain}: {displayChain: string}) => {
  const [chainBalances, setChainBalances] = useState<PortfolioBalanceProps[]>([]);
  const balanceByChain : BalanceByChainProps = useSelector(selectBalanceByChain);
  const balanceBySym : BalanceBySymProps = useSelector(selectBalanceBySym);
  useEffect(() => {
    if(displayChain !== 'Cross'){
      setChainBalances(balanceByChain[displayChain])
    } else {
      let result : PortfolioBalanceProps[] = []
      const symbols = Object.keys(balanceBySym)
      for(let i = 0; i < symbols.length; i++){
        result.push({
          symbol: symbols[i],
          balance: balanceBySym[symbols[i]].balance,
          pendingTxnTotal: balanceBySym[symbols[i]].pendingTxnTotal,
          netBalance: Math.max(balanceBySym[symbols[i]].netBalance, 0),
          assetValue: Math.max(balanceBySym[symbols[i]].assetValue, 0)
        })
      }
      setChainBalances(result)
    }
  }, [displayChain, balanceBySym, balanceByChain])

  return(
    <>
      <Grid templateColumns={{base:'repeat(3, 1fr)', lg: 'repeat(6, 1fr)'}} px={2} fontSize={{base: "11px", md: '14px'}} alignItems="center" textAlign={'start'} gap={2} whiteSpace='nowrap' width={'inherit'}>
        <GridItem colSpan={1}>
          <Text fontWeight={'extrabold'}>Symbol</Text>
        </GridItem>
        <GridItem display={{base: "none", lg: "inherit"}} colSpan={{base: 0, lg: 1}}><Text fontWeight={'extrabold'}>Vault Balance</Text></GridItem>
        <GridItem display={{base: "none", lg: "inherit"}} colSpan={{base: 0, lg: 1}}>
          <Text fontWeight={'extrabold'}>Open orders</Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Text fontWeight={'extrabold'}>Net Balance</Text>
          <Text as={"i"} display={{base: "inherit", lg: "none"}}>{`Value(in USD)`}</Text>
        </GridItem>
        <GridItem display={{base: "none", lg: "inherit"}} colSpan={{base: 0, lg: 1}}>
          <Text fontWeight={'extrabold'}>{`Value(in USD)`}</Text>
        </GridItem>
        <GridItem colSpan={1}>
          {displayChain !== "Cross" && <Text fontWeight={'extrabold'} textAlign={'center'}>Action</Text>}
        </GridItem>
        {chainBalances?.length > 0
          ?chainBalances.map((balance: any, i) => {
            return (
              <React.Fragment key={i}>
                <GridItem colSpan={1}>
                  <Text fontWeight={'bold'}>{balance.symbol}</Text>
                </GridItem>
                <GridItem display={{base: "none", lg: "inherit"}} colSpan={{base: 0, lg: 1}}>
                  <Text>{balance.balance?.toLocaleString(undefined, { maximumFractionDigits: 3})}</Text>
                </GridItem>
                <GridItem display={{base: "none", lg: "inherit"}} colSpan={{base: 0, lg: 1}}>
                  <Text fontStyle={'italic'}>{"(" + balance.pendingTxnTotal?.toLocaleString(undefined, { maximumFractionDigits: 3}) + ")"}</Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text fontWeight={'extrabold'}>{balance.netBalance?.toLocaleString(undefined, { maximumFractionDigits: 3})}</Text>
                  <Text as={"i"} display={{base: "inherit", lg: "none"}}>($ {balance.assetValue?.toLocaleString(undefined, {maximumFractionDigits: 3})})</Text>
                </GridItem>
                <GridItem display={{base: "none", lg: "inherit"}} colSpan={{base: 0, lg: 1}}>
                  <Text fontWeight={'extrabold'}>$ {balance.assetValue?.toLocaleString(undefined, {maximumFractionDigits: 3})}</Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Flex flexDir={'row'} gap={2} justifyContent='center' p={0}>
                    {displayChain !== "Cross" && <ActionButton displayChain={displayChain} actionType={'Withdraw'} symbol={balance.symbol} colorScheme={"messenger"}/>}
                  </Flex>
                </GridItem>
                <GridItem colSpan={{base: 3, lg: 6}}>
                  <Divider/>
                </GridItem>
              </React.Fragment>
            );
          })
          :<></>
        }
      </Grid>
      {chainBalances?.length > 0
      ? <></>
      : <Flex my={4} flexDir={'row'} alignItems={'center'} justifyContent='center' width={'full'}>
        <RecordNotFound message={"No Balance Found!"}/>
      </Flex>
      }
    </>
  )
}

export default UserBalances;

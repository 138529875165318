import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { defaultOrder } from "../constants";

export const userOrderSlice = createSlice({
  name: "userOrder",
  initialState: {
    open: [defaultOrder],
    historic: [defaultOrder]
  },
  reducers: {
    createUserOrder: (state, action) => {
      const orderRows:{open:any[], historic:any[]} = action.payload;
      return {
        ...state,
        open: orderRows.open,
        historic: orderRows.historic
      }
    },
  },
});

export const { createUserOrder } = userOrderSlice.actions;

export const selectOpen = (state: RootState) => state.userOrder.open;
export const selectHistoric = (state: RootState) => state.userOrder.historic;

export default userOrderSlice.reducer;

import { Avatar, Flex, Tooltip, useColorModeValue, useDisclosure } from "@chakra-ui/react"
import { Dispatch, SetStateAction } from "react"
import { FaRegQuestionCircle } from "react-icons/fa"
import SwitchSelector from "react-switch-selector"
import { GrDocumentText } from "react-icons/gr";
import Transfers from "../Transfers";

export default function OrderTypeSwitch({
  setOrderType,
}:{
  setOrderType: Dispatch<SetStateAction<string>>
}){
  const options = [
    {label: "Market", value: "Market"},
    {label: "Limit", value: "Limit"}
  ]
  const backgroundColor = useColorModeValue("#dfe7f2","#1A202C");
  const selectedBackgroundColor = useColorModeValue("#edf2f6", "#2D3748");
  const fontColor = useColorModeValue('black', 'white');
  const selectedFontColor = useColorModeValue("#09a1bf", '#0BC5EA');
  const avatarBgColor = useColorModeValue('cyan.600', 'cyan.800')
  const {onOpen, isOpen, onClose} = useDisclosure();

  const TransferRecordBtn = () => (
    <Tooltip label="Transfer Record">
      <Avatar
      _hover={{cursor: "pointer", opacity: "80%"}}
      onClick={onOpen}
      bg={avatarBgColor}
      size={{base: 'xs', lg: 'sm'}}
      icon={<GrDocumentText/>}
      />
    </Tooltip>
  )
  return(
    <Flex flexDir={'row'}>
      <Flex width={'100%'} justifyContent='center'>
        {/* <Flex width={{base: "80%", md:'50%'}} height={'40px'} alignSelf={'center'} fontWeight={'bold'} ml={8}>
          <SwitchSelector
            onChange={(value) => setOrderType(value as string)}
            options={options}
            backgroundColor={backgroundColor}
            selectedBackgroundColor={selectedBackgroundColor}
            selectedFontColor={selectedFontColor}
            fontColor={fontColor}
            wrapperBorderRadius={10}
            optionBorderRadius={10}
          />
        </Flex> */}
      </Flex>
      <TransferRecordBtn/>
      <Transfers isOpen={isOpen} onClose={onClose}/>
    </Flex>
  )
}
import { EditIcon } from "@chakra-ui/icons";
import { Avatar, Button, Divider, Flex, Grid, GridItem, Input, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDestChain, selectDestSym, selectSingleBridgingDirectionDisplayId, selectSingleBridgingRate, selectSourceChain, setSingleCustomRate, setSingleRoutingPath } from "../../features/mesConvertSlice";
import { AppDispatch } from "../../store";
import { RoutingPathProps } from "../../types";
import { countDecimals } from "../../utils/numbers";

export default function PathRate({
  routingPath,
  orderType,
  customRate,
  setIsEditing,
  setHasEditedPrice
}: {
  routingPath: RoutingPathProps[]
  orderType: string,
  customRate: string,
  setIsEditing: Dispatch<SetStateAction<"sourceQty" | "destQty" | undefined>>
  setHasEditedPrice: Dispatch<SetStateAction<boolean>>
}){
  const { isOpen, onOpen, onClose } = useDisclosure();
  const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
  const bridgingRate = useSelector(selectSingleBridgingRate);
  const bridgingDirectionDisplayId = useSelector(selectSingleBridgingDirectionDisplayId);
  const sourceChain = useSelector(selectSourceChain);
  const destChain = useSelector(selectDestChain);
  const destSym = useSelector(selectDestSym);
  const dispatch = useDispatch<AppDispatch>();
  
  function PathRates(){
    const [finalRoutingPath, setFinalRoutingPath] = useState<RoutingPathProps[]>(routingPath)
    
    function handleRateChange(currentIndex: number, newRate: string){
      if(isNaN(Number(newRate))) newRate = ""
      if(Number(newRate) < 0) newRate = ""
      if(countDecimals(newRate) >= 8) return
      const formattedNewRate = newRate.replace(/^0+(?=\d)/, '')
      let tempRoutingPath = JSON.parse(JSON.stringify(finalRoutingPath))
      //change current index's rate and receiveAmount
      tempRoutingPath[currentIndex].interimRate = formattedNewRate
      const newReceiveAmount = tempRoutingPath[currentIndex].directionDisplayId === 0
      ? (Number(tempRoutingPath[currentIndex].interimPayAmount) / Number(formattedNewRate)).toFixed(tempRoutingPath[currentIndex].assetPair?.baseDisplayDecimal)
      : (Number(tempRoutingPath[currentIndex].interimPayAmount) * Number(formattedNewRate)).toFixed(tempRoutingPath[currentIndex].assetPair?.quoteDisplayDecimal)
      tempRoutingPath[currentIndex].interimReceiveAmount = newReceiveAmount
      //calculate the new pay and receive amount for the rest of the indices
      let lastReceiveAmount = newReceiveAmount
      for(let i = currentIndex + 1; i < finalRoutingPath.length; i++){
        tempRoutingPath[i].interimPayAmount = lastReceiveAmount
        const receiveAmount = tempRoutingPath[i].directionDisplayId === 0
        ? (Number(lastReceiveAmount) / Number(tempRoutingPath[i].interimRate)).toFixed(tempRoutingPath[i].assetPair?.baseDisplayDecimal)
        : (Number(lastReceiveAmount) * Number(tempRoutingPath[i].interimRate)).toFixed(tempRoutingPath[i].assetPair?.quoteDisplayDecimal)
        tempRoutingPath[i].interimReceiveAmount = receiveAmount
        lastReceiveAmount = receiveAmount
      }
      setFinalRoutingPath(tempRoutingPath)
    }

    function confirmPathRate(){
      setIsEditing("sourceQty")
      setHasEditedPrice(true)
      let finalRate: number = 0
      for(const path of finalRoutingPath){
        if(finalRate > 0){
          finalRate = path.directionDisplayId === 0
          ? finalRate / Number(path.interimRate)
          : finalRate * Number(path.interimRate)
        } else {
          finalRate = Number(path.interimRate)
        }
      }
      //determine the overall final rate based on the bridgingDirectionDisplayId
      if(bridgingDirectionDisplayId === 0){
        finalRate = 1 / finalRate
      }
      dispatch(setSingleRoutingPath(finalRoutingPath))
      dispatch(setSingleCustomRate(finalRate.toFixed(destSym.displayDecimal)))
      onClose()
    }

    return(
      <Flex flexDir={'column'} gap={3} align="center">
        {finalRoutingPath.map((path, index) => {
          let sourceLogoPath: string = ""
          let destLogoPath: string = ""
          let receiveAmount: string = path?.interimReceiveAmount
          let payAmount: string = path?.interimPayAmount
      
          if(path?.directionDisplayId === 0){
            //buy order, source sym is quote and dest sym is base
            sourceLogoPath = path?.assetPair?.quoteAsset?.logoUrl
            destLogoPath = path?.assetPair?.baseAsset?.logoUrl
          } else {
            //sell order, source sym is base and dest sym is quote
            sourceLogoPath = path?.assetPair?.baseAsset?.logoUrl
            destLogoPath = path?.assetPair?.quoteAsset?.logoUrl
          }
          return(
            <Grid key={index} templateColumns={'repeat(7, 1fr)'} alignItems={'center'} fontSize={{base: "11px", lg: "13px"}} mt={2} width="100%">
              <GridItem colSpan={1}>{`Step ${index + 1}`}</GridItem>
              <GridItem colSpan={2}>
                <Text as={'b'} whiteSpace="nowrap">{`${Number(payAmount) <= 0 || Number(payAmount) === Infinity? "--" : payAmount} ${path?.sourceSym}`} </Text>
                <Avatar bg={avatarBgColor} size={'xs'} name={path?.sourceSym} src={sourceLogoPath}/>
                <Text as={'b'} whiteSpace="nowrap"> on </Text>
                <Avatar bg={avatarBgColor} size={'xs'} name={sourceChain?.name} src={useColorModeValue(sourceChain?.lightLogo, sourceChain?.darkLogo)}/>
              </GridItem>
              <GridItem colSpan={2}>
                <Text as={'b'} whiteSpace="nowrap">{`${Number(receiveAmount) <= 0 || Number(receiveAmount) === Infinity? "--" : receiveAmount} ${path?.destSym}`} </Text>
                <Avatar bg={avatarBgColor} size={'xs'} name={path?.destSym} src={destLogoPath}/>
                <Text as={'b'} whiteSpace="nowrap"> on </Text>
                <Avatar bg={avatarBgColor} size={'xs'} name={destChain?.name} src={useColorModeValue(destChain?.lightLogo, destChain?.darkLogo)}/>
              </GridItem>
              <GridItem colSpan={2}>
                <Input
                  type="number"
                  variant={'filled'}
                  width={"100%"}
                  fontWeight={'bold'}
                  fontSize={{base: "11px", lg: "13px"}}
                  value={path?.interimRate}
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => handleRateChange(index, e.target.value)}
                />
              </GridItem>
            </Grid>
          )
        })}
        <Button onClick={confirmPathRate} colorScheme={'twitter'} fontSize={{base: "11px", lg: "13px"}}>Confirm</Button>
      </Flex>
    )
  }

  return(
    <Flex 
      flexDir={'row'} 
      width={"100%"} 
      align={'center'} 
      cursor={"pointer"}
      gap={2}
      onClick={onOpen}
      >
      <Text
        fontSize="16px"
        fontWeight={'extrabold'}
        >
        {orderType === 'Limit' && customRate ? customRate : Number(Number(bridgingRate).toFixed(destSym.displayDecimal))}
      </Text>
      <EditIcon/>
      <Modal size={'4xl'} isOpen={isOpen} onClose={onClose} isCentered motionPreset='slideInBottom' closeOnOverlayClick={false}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontSize={{base: "sm", sm:'md'}}>Edit conversion rate</ModalHeader>
          <Divider/>
          <ModalBody justifyContent={'center'} display='flex' flexDir={'column'} gap={4} alignItems="center">
            <Flex flexDir={'column'} width={'100%'} >
              <Grid templateColumns={'repeat(7, 1fr)'} alignItems={'center'} fontSize={{base: "11px", lg: "13px"}} fontWeight="extrabold">
                <GridItem colSpan={1}>Steps</GridItem>
                <GridItem colSpan={2}>You Pay</GridItem>
                <GridItem colSpan={2}>You Receive</GridItem>
                <GridItem colSpan={2}>Rate</GridItem>
              </Grid>
              <PathRates/>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
import { extendTheme, useColorModeValue } from '@chakra-ui/react'
import { StepsTheme as Steps } from "chakra-ui-steps";

export const breakpoints = {
  sm: '562px',
  md: '775px',
  lg: '1032px', //default:960
  xl: '1250px',
  '2xl': '1536px',
}

export const theme = extendTheme({
  breakpoints, 
  useSystemColorMode: false,
  initialColorMode: "dark",
  components: {
    Steps
  },
  styles: {
    global: () => ({
      body: {
        bg: useColorModeValue('#e7edf5','#070D15'),
      }
    })
  }
})
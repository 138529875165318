import { ChevronDownIcon } from "@chakra-ui/icons"
import { HStack, Avatar, Button, Flex, Modal, Text, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, useColorModeValue, Skeleton, Input, Divider, AvatarBadge, useColorMode, VStack } from "@chakra-ui/react"
import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDestChain, selectDestSym, selectIsLoadingRate, setIsLoadingRate } from "../../features/mesConvertSlice";
import { countDecimals } from "../../utils/numbers";
import { DebouncedFunc } from 'lodash';
import { AppDispatch } from "../../store";
import TokenPicker from "./TokenPicker";

export default function DestSym({
  orderType,
  destQuantity,
  setDestQuantity,
  isEditing,
  setIsEditing,
  isLockedOrder,
  hasEditedPrice,
  debouncedGetBridgingRate
} : {
  orderType: string,
  destQuantity: string,
  setDestQuantity: Dispatch<SetStateAction<string>>,
  isEditing: "sourceQty" | "destQty" | undefined,
  setIsEditing: Dispatch<SetStateAction<"sourceQty" | "destQty" | undefined>>,
  isLockedOrder: boolean,
  hasEditedPrice: boolean,
  debouncedGetBridgingRate:{
    debouncedCallback: DebouncedFunc<() => void>;
    cancel: () => void;
  }
}){
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const isLoadingRate = useSelector(selectIsLoadingRate);
  const destChain = useSelector(selectDestChain);
  const destSym = useSelector(selectDestSym);
  const dispatch = useDispatch<AppDispatch>();

  function handleDestQtyChange(quantityInput:string){
    setIsEditing('destQty')
    if(countDecimals(quantityInput) <= destSym.displayDecimal){
      const finalInput = quantityInput.replace(/^0+(?=\d)/, '')
      setDestQuantity(finalInput);
      //refresh market rate if user does not specify a rate before & it is a limit order
      if(!hasEditedPrice && orderType === 'Limit'){
        dispatch(setIsLoadingRate(true));
        debouncedGetBridgingRate.debouncedCallback();
      }
    }
  }
  return(
    <Flex flexDir={'column'} bgColor={bgColor} rounded="2xl" p={3} gap={2}>
      <HStack justifyContent={'space-between'}>
        {orderType === "Market"
        ? <Text fontWeight={'bold'}> Receive (Estimate):</Text>
        : <Text fontWeight={'bold'}> Receive: </Text>
        }
      </HStack>
      <HStack justifyContent={'space-between'}>
        {isLoadingRate && isEditing !== 'destQty'
        ? <Flex flexDir={'row'} flex={1}>
            <Skeleton h={'30px'} w={"full"} rounded="md"/>
          </Flex>
        : <Input
          width={'auto'}
          type="number"
          variant={'unstyled'}
          fontSize={"16px"}
          fontWeight="extrabold"
          value={destQuantity}
          isDisabled={isLockedOrder}
          onWheel={(e) => e.currentTarget.blur()}
          onChange={(e) => handleDestQtyChange(e.target.value)}
        />
        }
        <Button isDisabled={isLockedOrder} onClick={onOpen} minW={"200px"}> 
          <HStack spacing={4} px={2}>
            <Avatar bg={avatarBgColor} size={{base: "xs", sm:'sm'}} name={destSym.name} src={destSym.logoUrl}>
              <AvatarBadge>
                <Avatar bg={avatarBgColor} size={"2xs"} name={destSym.name} src={colorMode === "light" ? destChain.lightLogo : destChain.darkLogo}/>
              </AvatarBadge>
            </Avatar>
            <VStack spacing={0} alignItems={"start"}>
              <Text as={'b'} fontSize={{base: "xs", sm:'sm'}}>{destSym.symbol}</Text>
              <Text fontSize={"xs"} opacity={0.7}>on {destChain.name}</Text>
            </VStack>
            <ChevronDownIcon/>
          </HStack>
        </Button>
      </HStack>
      {/* <SolanaConnectButton/> */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset='slideInBottom'>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontSize={{base: "sm", sm:'md'}}>Swap to</ModalHeader>
          <ModalCloseButton />
          <Divider/>
          <ModalBody>
            <TokenPicker direction="dest" onClose={onClose}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
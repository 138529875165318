import {
  Heading,
  Text,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import BalanceChart from "./BalanceChart";
import { BalanceByChainProps } from "../../types";
import { selectBalanceByChain, selectTotalAssetValue } from "../../features/userPortfolioSlice";
import { useEffect, useState } from "react";
import UserBalances from "./UserBalances";
import React from "react";
import LoadingPage from "../../pages/LoadingPage";

const UserPortfolio = ({displayChain} : {displayChain: string}) => {
  const balanceByChain : BalanceByChainProps = useSelector(selectBalanceByChain)
  const totalAssetValue : number = useSelector(selectTotalAssetValue)
  const [assetValueByChain, setAssetValueByChain] = useState<number>(totalAssetValue)
  const textColor = useColorModeValue("gray.600", "gray.400")
  useEffect(() => {
    if(displayChain !== 'Cross'){
      if(balanceByChain[displayChain] && balanceByChain[displayChain].length > 0){
        const assetValue = balanceByChain[displayChain].reduce((acc, current) => acc + current.assetValue, 0)
        setAssetValueByChain(assetValue)
      }
    } else {
      setAssetValueByChain(totalAssetValue)
    }
  }, [displayChain, totalAssetValue])

  return(
    <React.Fragment>
      {totalAssetValue > -1
      ?
      <Flex flexDir={"column"} width={"full"} justifyContent={"flex-start"} alignItems={"center"} py={{base: 5, md: 10}} px={{base: 2, md: 10}} overflow='scroll' css={{"msOverflowStyle": "none", "scrollbarWidth": "none", "&::-webkit-scrollbar": {display: "none"}}}>
        <Flex flexDir={'column'} alignItems='center'>
          <Text fontSize={'lg'} color={textColor}> TOTAL PORTFOLIO VALUE </Text>
          <Heading> ${assetValueByChain?.toLocaleString(undefined, { maximumFractionDigits: 3 })} </Heading>
          <Text as={'i'} color={textColor} fontSize={'10px'}> Updated as at {new Date().toLocaleTimeString()}</Text>
          <BalanceChart displayChain = {displayChain}/>
        </Flex>
        <Flex flexDir="column" gap={4} width="100%">
          <UserBalances displayChain = {displayChain}/>
        </Flex>
      </Flex>
      :
      <LoadingPage/>
      }
    </React.Fragment>
  )
}

export default UserPortfolio
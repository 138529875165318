import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Button,
  Divider,
  Text,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { selectBalanceByChain } from '../../features/userPortfolioSlice';
import { BalanceByChainProps } from '../../types';
import * as Router from "react-router-dom";
import { ChevronDownIcon } from '@chakra-ui/icons';
import { pickColorByChain } from '../../utils/colorPicker';
import { selectMarketByChain } from '../../features/mesMarketSlice';

export default function Sidebar({
  pathName,
  displayChain, 
  setDisplayChain
}:{
  pathName: string,
  displayChain: string, 
  setDisplayChain: Dispatch<SetStateAction<string>>
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const balanceByChain : BalanceByChainProps = useSelector(selectBalanceByChain)

  return (
    <Box minH="90vh" width={'full'} bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        displayChain={displayChain}
        setDisplayChain={setDisplayChain}
        balanceByChain={balanceByChain}
        pathName={pathName}
        onClose={onClose}
        display={{ base: 'none', xl: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="md">
        <DrawerContent>
          <SidebarContent 
            displayChain={displayChain}
            setDisplayChain={setDisplayChain}
            balanceByChain={balanceByChain}
            pathName={pathName}
            onClose={onClose} 
            />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', xl: 'none' }} pathName={pathName} onOpen={onOpen} onClose={onClose}/>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  displayChain: string;
  setDisplayChain: Dispatch<SetStateAction<string>>;
  balanceByChain: BalanceByChainProps;
  pathName: string;
  onClose: () => void;
}

const SidebarContent = ({ displayChain, setDisplayChain, balanceByChain, pathName, onClose, ...rest }: SidebarProps) => {
  const marketByChain = useSelector(selectMarketByChain);
  return (
    <Flex
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      flexDir={"column"}
      w={'full'}
      h={"full"}
      pt={4}
      {...rest}>
      <CloseButton display={{ base: 'flex', xl: 'none' }} onClick={onClose} alignSelf='flex-end' mx={4}/>
      <Flex flexDir={'column'} px={4} gap={4}>
        <Text fontSize={'14px'} fontWeight="bold">Select display chain:</Text>
        <Box 
          className="pulse" 
          borderRadius={"5px"} 
          width={"full"} 
          background={pickColorByChain(displayChain)} 
          backgroundSize={"250% 250%"}
          >
          <Menu>
            <MenuButton 
              as={Button} 
              rightIcon={<ChevronDownIcon/>}
              width="full"
              colorScheme={'none'}
              fontSize={{base: "12px", xl: "14px"}}
              color={"white"}
              fontWeight={'bold'}
              > {displayChain} </MenuButton>
            <MenuList 
              fontSize={{base: "12px", xl: "14px"}}
              fontWeight={'bold'} 
              >
              <MenuOptionGroup
                defaultValue={displayChain}
                onChange = {(chain) => {
                  if(typeof chain === 'string'){
                    setDisplayChain(chain)
                  }
                }} 
                >
                <Text mx={2} color={useColorModeValue("cyan.500", "cyan.400")}>Cross</Text>
                <Divider my={1}/>
                <MenuItemOption value={"Cross"}> Cross-rollups </MenuItemOption>
                <Divider my={1}/>
                {marketByChain.map((market, i) => (
                  <MenuItemOption key={i} value={market.chain.name}> {market.chain.desc} </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Box>
      </Flex>  
      <Divider my={4}/>
    </Flex>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  route: string;
  pathName: string;
  onClose: () => void;
  children?: string | number;
}
const NavItem = ({ icon, route, pathName, onClose, children}: NavItemProps) => {
  return (
    <Router.Link
      to={route}
      >
      <Flex
        alignItems="center"
        p={4}
        m={2}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        fontSize={{ base: 13, xl: 14 }}
        fontWeight={'bold'}
        gap={4}
        bgColor={pathName === route? useColorModeValue('gray.200', 'gray.800') : "inherit"}
        onClick={onClose}
        _hover={{
          bg: useColorModeValue('cyan.700', 'gray.800'),
          color: useColorModeValue('white', 'cyan.400'),
        }}
        >
        {icon && (
          <Icon
            mr={{base: 0, xl: 4}}
            fontSize="16"
            _groupHover={{
              color: useColorModeValue('white', 'cyan.400'),
            }}
            as={icon}
          />
        )}
        {children ?? ""}
      </Flex>
    </Router.Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  onClose: () => void;
  pathName: string;
}
const MobileNav = ({ onOpen, onClose, pathName, ...rest }: MobileProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDir="column"
      {...rest}>
      <IconButton
        onClick={onOpen}
        bgColor="none"
        my={2}
        aria-label="open menu"
        icon={<FiMenu />}
      />
    </Flex>
  );
};
import { Tabs, TabList, Tab, TabPanels, TabPanel, Box, Flex, Divider, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { useSelector } from "react-redux"
import { selectClosedHooks, selectOpenHooks } from "../../features/userHookSlice"
import UserHooks from "./UserHooks"

export default function Transfers({
  isOpen,
  onClose
} : {
  isOpen: boolean
  onClose: () => void
}){
  const openHooks = useSelector(selectOpenHooks);
  const closedHooks = useSelector(selectClosedHooks);

  return(
    <Modal size={"6xl"} isOpen={isOpen} onClose={onClose} isCentered motionPreset='slideInBottom'>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader fontSize={{base: "sm", sm:'md'}}>Transfer Records</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
      <Tabs align="center" variant='solid-rounded' colorScheme='twitter' p={{base: 2, lg: 4}} width='100%'>
        <TabList> 
          <Tab fontSize={{base: "12px", md: '14px'}}>
            <Flex flexDir={'row'} alignItems='center' gap={2}>
              <Text>Pending Transfers</Text>
              {openHooks.length > 0
              ? <Box px={"10px"} py={"5px"} rounded={'lg'} bgColor={'gray.400'} color={'white'} fontSize={'10px'}> {openHooks.length}</Box>
              : <></>
              }
            </Flex>
          </Tab>
          <Tab fontSize={{base: "12px", md: '14px'}}>Completed Transfers</Tab>
        </TabList>
        <Divider my={4}/>
        <TabPanels>
          <TabPanel minHeight={"calc(65vh)"}>
            <UserHooks hooks={openHooks} isOpenOrderTab={true}/>
          </TabPanel>
          <TabPanel minHeight={"calc(65vh)"}>
            <UserHooks hooks={closedHooks} isOpenOrderTab={false}/>
          </TabPanel>
        </TabPanels>
      </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
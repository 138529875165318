import { ChevronDownIcon } from "@chakra-ui/icons"
import { HStack, Avatar, Button, Flex, Modal, Text, ModalBody, ModalContent, ModalOverlay, useDisclosure, useColorModeValue, Input, Skeleton, Divider, ModalCloseButton, ModalHeader, AvatarBadge, useColorMode, VStack } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoadingRate, selectSourceChain, selectSourceSym, setIsLoadingRate } from "../../features/mesConvertSlice";
import { AppDispatch } from "../../store";
import { countDecimals } from "../../utils/numbers";
import { DebouncedFunc } from 'lodash';
import TokenPicker from "./TokenPicker";
import ConnectButton from "../Wallet/ConnectButton";
import { useAccount, useBalance } from "wagmi";

export default function SourceSym({
  orderType,
  sourceQuantity,
  setSourceQuantity,
  isEditing,
  setIsEditing,
  isLockedOrder,
  hasEditedPrice,
  setHasExceedNetBal,
  debouncedGetBridgingRate,
} : {
  orderType: string,
  sourceQuantity: string,
  setSourceQuantity: Dispatch<SetStateAction<string>>,
  isEditing: "sourceQty" | "destQty" | undefined,
  setIsEditing: Dispatch<SetStateAction<"sourceQty" | "destQty" | undefined>>,
  isLockedOrder: boolean,
  hasEditedPrice: boolean,
  setHasExceedNetBal:  Dispatch<SetStateAction<boolean>>,
  debouncedGetBridgingRate:{
    debouncedCallback: DebouncedFunc<() => void>;
    cancel: () => void;
  }
}){
  const dispatch = useDispatch<AppDispatch>();
  const { address, chainId } = useAccount();
  const [sourceBalance, setSourceBalance] = useState<string>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
  const isLoadingRate = useSelector(selectIsLoadingRate);
  const sourceChain = useSelector(selectSourceChain);
  const sourceSym = useSelector(selectSourceSym);
  const nativeTokenBalance = useBalance({address: address});
  const sourceTokenBalance = useBalance({
    address: address,
    token: sourceSym.tokenAddress as `0x${string}`,
    unit: sourceSym.decimal
  })

  //determine source balance
  useEffect(() => {
    //not fetching chain balance if wallet config does not match
    if(sourceChain.chainId !== chainId) {
      setSourceBalance("--")
      return
    }
    if (sourceSym.symbol === sourceChain.nativeToken.symbol) {
      if(nativeTokenBalance && nativeTokenBalance.data){
        setSourceBalance(nativeTokenBalance.data.formatted)
      }
    } else {
      setSourceBalance(sourceTokenBalance.data?.formatted)
    }
  }, [nativeTokenBalance, sourceTokenBalance])
  
  //compare new source net balance with user's qty input
  useEffect(() => {
    if(Number(sourceQuantity) > Number(sourceBalance)){
      setHasExceedNetBal(true)
      debouncedGetBridgingRate.cancel();
      dispatch(setIsLoadingRate(false));
      return
    } else {
      setHasExceedNetBal(false)
    }
  }, [sourceBalance])

  function handleSourceQtyChange(quantityInput:string){
    setIsEditing('sourceQty')
    if(countDecimals(quantityInput) <= sourceSym.displayDecimal){
      const finalInput = quantityInput.replace(/^0+(?=\d)/, '')
      setSourceQuantity(finalInput);
      //check if the input amount exceeds balance
      if(Number(quantityInput) > Number(sourceBalance)){
        setHasExceedNetBal(true)
        debouncedGetBridgingRate.cancel();
        dispatch(setIsLoadingRate(false));
        return
      } else {
        setHasExceedNetBal(false)
      }
      //refresh market rate if user does not specify a rate before & it is a limit order
      if(orderType === 'Limit' && !hasEditedPrice){
        dispatch(setIsLoadingRate(true));
        debouncedGetBridgingRate.debouncedCallback();
      }
    }
  }

  return(
    <Flex flexDir={'column'} bgColor={bgColor} rounded="2xl" p={3} gap={2}>
      <HStack justifyContent={'space-between'}>
        <Text fontWeight={'bold'}> Send: </Text>
        <HStack fontSize={'12px'}>
          <Text>Balance:</Text>
          <Avatar bg={avatarBgColor} size={'2xs'} name={sourceSym.name} src={sourceSym.logoUrl}/>
          <Text
            display={'inline'}
            fontWeight={'bold'}
            cursor='pointer'
            onClick={() => !isLockedOrder && sourceBalance? handleSourceQtyChange(Number(sourceBalance).toFixed(sourceSym.displayDecimal)) : null}
            >{Number(sourceBalance) > 0 ? Number(sourceBalance).toFixed(sourceSym.displayDecimal) : "--"}
          </Text>
        </HStack>
      </HStack>
      <HStack justifyContent={'space-between'}>
      {isLoadingRate && isEditing !== 'sourceQty'
        ? <Flex flexDir={'row'} flex={1}>
            <Skeleton h={'30px'} w={"full"} rounded="md"/>
          </Flex>
        : <Input
          width={'auto'}
          type="number"
          variant={'unstyled'}
          fontSize={"16px"}
          fontWeight="extrabold"
          value={sourceQuantity}
          isDisabled={isLockedOrder}
          onWheel={(e) => e.currentTarget.blur()}
          onChange={(e) => handleSourceQtyChange(e.target.value)}
        />
      }
        <Button isDisabled={isLockedOrder} onClick={onOpen} minW={"200px"}> 
          <HStack spacing={4} px={2}>
            <Avatar bg={avatarBgColor} size={{base: "xs", sm:'sm'}} name={sourceSym.name} src={sourceSym.logoUrl}>
              <AvatarBadge>
                <Avatar bg={avatarBgColor} size={"2xs"} name={sourceSym.name} src={colorMode === "light" ? sourceChain.lightLogo : sourceChain.darkLogo}/>
              </AvatarBadge>
            </Avatar>
            <VStack spacing={0} alignItems={"start"}>
              <Text as={'b'} fontSize={{base: "xs", sm:'sm'}}>{sourceSym.symbol}</Text>
              <Text fontSize={"xs"} opacity={0.7}>on {sourceChain.name}</Text>
            </VStack>
            <ChevronDownIcon/>
          </HStack>
        </Button>
      </HStack>
      <ConnectButton/>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"} isCentered motionPreset='slideInBottom'>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader fontSize={{base: "sm", sm:'md'}}>Swap from</ModalHeader>
          <ModalCloseButton />
          <Divider/>
          <ModalBody>
            <TokenPicker direction="source" onClose={onClose}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

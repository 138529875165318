import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { BalanceByChainProps, BalanceBySymProps } from "../types";
import { defaultBalanceBySym, defaultL2Chain } from "../constants";

const initialBalancesBySym: BalanceBySymProps = {
  'ETH': defaultBalanceBySym,
  'USDC': defaultBalanceBySym,
  'USDT': defaultBalanceBySym,
}
const initialChainBalance = Object.keys(initialBalancesBySym).map((sym) => {
  return {
    symbol: sym,
    balance: 0,
    pendingTxnTotal: 0,
    netBalance: 0,
    assetValue: 0
  }
})

let initialBalancesByChain : BalanceByChainProps = {}
initialBalancesByChain[defaultL2Chain.name] = initialChainBalance

export const userPortfolioSlice = createSlice({
  name: "userPortfolio",
  initialState: {
    balancesBySym: initialBalancesBySym,
    balancesByChain: initialBalancesByChain,
    totalAssetValue: -1
  },
  reducers: {
    setUserPortfolio: (state, action) => {
      const portfolioData = action.payload;
      //assign balance by sym
      const currentSyms = Object.keys(state.balancesBySym)
      const currentChains = Object.keys(state.balancesByChain)
      for(const sym of currentSyms){
        if(portfolioData.balancesBySym.hasOwnProperty(sym)){
          state.balancesBySym[sym] = portfolioData.balancesBySym[sym]
        } else {
          state.balancesBySym[sym] = initialBalancesBySym[sym]
        }
      }
      //assign balance by chain
      state.balancesByChain = portfolioData.balancesByChain
    },
    setTotalAssetValue: (state, action) => {
      const totalAssetValue = action.payload;
      return{
        ...state,
        totalAssetValue: totalAssetValue
      }
    }
  }
});

export const { setUserPortfolio, setTotalAssetValue } = userPortfolioSlice.actions;

export const selectBalanceBySym = (state: RootState) => state.userPortfolio.balancesBySym;
export const selectBalanceByChain = (state: RootState) => state.userPortfolio.balancesByChain;
export const selectTotalAssetValue = (state: RootState) => state.userPortfolio.totalAssetValue;

export default userPortfolioSlice.reducer;

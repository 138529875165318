import { InfoIcon } from "@chakra-ui/icons";
import { Flex, Text, Input, HStack, useColorModeValue, Tooltip, Box, Skeleton, Avatar, Button } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSingleBridgingDirectionDisplayId, selectSingleBridgingRate, selectIsLoadingRate, setIsLoadingRate, selectSingleIsMultiRoute, selectSingleRoutingPath, setSingleCustomRate, selectSingleCustomRate, selectSourceChain, selectSourceSym, selectDestChain, selectDestSym } from "../../features/mesConvertSlice";
import { countDecimals } from "../../utils/numbers";
import { DebouncedFunc } from 'lodash';
import { AppDispatch } from "../../store";
import Slippage from "./Slippage";
import PathRate from "./PathRate";

export default function RateBox({
  orderType,
  slippagePercent,
  setSlippagePercent,
  setHasEditedPrice,
  setIsEditing,
  isLockedOrder,
  debouncedGetBridgingRate,
}: {
  orderType: string
  slippagePercent: string
  setSlippagePercent: Dispatch<SetStateAction<string>>
  setIsEditing: Dispatch<SetStateAction<"sourceQty" | "destQty" | undefined>>
  isLockedOrder: boolean
  setHasEditedPrice: Dispatch<SetStateAction<boolean>>
  debouncedGetBridgingRate:{
    debouncedCallback: DebouncedFunc<() => void>;
    cancel: () => void;
  }
}){
  const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
  const tooltipBgColor = useColorModeValue("gray.500", "gray.100")
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const dispatch = useDispatch<AppDispatch>();
  const [timerClock, setTimerClock] = useState<number>(0);
  const bridgingRate = useSelector(selectSingleBridgingRate);
  const bridgingDirectionDisplayId = useSelector(selectSingleBridgingDirectionDisplayId);
  const routingPath = useSelector(selectSingleRoutingPath);
  const isLoadingRate = useSelector(selectIsLoadingRate);
  const isMultiRoute = useSelector(selectSingleIsMultiRoute);
  const singleCustomRate = useSelector(selectSingleCustomRate);
  const sourceChain = useSelector(selectSourceChain);
  const sourceSym = useSelector(selectSourceSym);
  const destChain = useSelector(selectDestChain);
  const destSym = useSelector(selectDestSym);

  function handleRateChange(rateInput:string){
    setHasEditedPrice(true)
    //Market order cannot set custom rate
    if(orderType === "Market") return 
    //replace by space if no input by user
    if(!rateInput) {
      dispatch(setSingleCustomRate(" "))
      return
    }
    //fetch display decimal according to direction displayId
    if(countDecimals(rateInput) <= destSym.displayDecimal){
      const finalInput = rateInput.replace(/^0+(?=\d)/, '')
      dispatch(setSingleCustomRate(finalInput))
      setSlippagePercent("")
    }
  }

  const RateInfo = () => {
    return(
      <Flex flexDir={'column'} p={4} alignItems='flex-start' color={useColorModeValue("black", "white")}>
        <Text fontWeight={'extrabold'} fontSize={"xs"}> Conversion rate is for reference only. Actual rate could vary according to price movement.</Text>
      </Flex>
    )
  }

  const RateAltView = () => {
    const chainRate: string = getChainRate()
    function getChainRate(){
      //slippage percent provided
      if(slippagePercent !== ""){
        return bridgingDirectionDisplayId === 0? (1 / (1 + Number(slippagePercent) / 100)).toFixed(8) : (1 - Number(slippagePercent) / 100).toFixed(8)
      } 
      //custom rate provided
      if(singleCustomRate){
        return bridgingDirectionDisplayId === 0? (1 / Number(singleCustomRate))?.toFixed(8) : singleCustomRate
      } 
      //no custom rate set, use the original reference bridging rate
      return bridgingDirectionDisplayId === 0? (1 / Number(bridgingRate))?.toFixed(8) :Number(bridgingRate).toFixed(destSym.displayDecimal)
    }
    return(
      <Flex flexDir="row" p={2} whiteSpace={'nowrap'} alignItems="center" gap={2} width="full" color={useColorModeValue("black", "white")}>
        <Text> 1 </Text>
        <Avatar bg={avatarBgColor} size={'2xs'} name={sourceSym.name} src={sourceSym.logoUrl}/>
        <Text> {sourceSym.symbol} on </Text>
        <Avatar bg={avatarBgColor} size={'xs'} name={sourceSym.name} src={useColorModeValue(sourceChain?.lightLogo, sourceChain?.darkLogo)}/>
        <Text> = {Number(chainRate) }</Text>
        <Avatar bg={avatarBgColor} size={'2xs'} name={destSym.name} src={destSym.logoUrl}/>
        <Text> {destSym.symbol} on </Text>
        <Avatar bg={avatarBgColor} size={'xs'} name={sourceSym.name} src={useColorModeValue(destChain?.lightLogo, destChain?.darkLogo)}/>
      </Flex>
    )
  }

  function handleRefreshRate(){
    dispatch(setSingleCustomRate(""))
    setSlippagePercent("");
    setHasEditedPrice(false);
    dispatch(setIsLoadingRate(true));
    debouncedGetBridgingRate.debouncedCallback();
    setTimerClock(5);
  }

  //timer 
  useEffect(() => {
    if(orderType === 'Market' && isLockedOrder ||
      orderType === 'Limit'){
      const timer = setTimeout(function() {
        setTimerClock(Math.max(timerClock - 1, 0));
      }, 1000)
  
      return () => { // this should work flawlessly besides some milliseconds lost here and there 
        clearTimeout(timer)
      }
    }
  }, [timerClock, isLockedOrder, orderType]);

  //set rate if slippage rate changes
  useEffect(() => {
    if(slippagePercent === "") return
    let postSlipppageRate: number
    if(bridgingDirectionDisplayId === 0){
      postSlipppageRate = 1 * (1 + Number(slippagePercent) / 100)
    } else {
      postSlipppageRate = 1 * (1 - Number(slippagePercent) / 100)
    }
    dispatch(setSingleCustomRate(postSlipppageRate.toFixed(destSym.displayDecimal)))
  }, [slippagePercent])

  
  
  return(
    <Flex flexDir={'column'} bgColor={bgColor} rounded="2xl" p={3} gap={2} width="100%">
      <HStack>
        {orderType === 'Market'
        ? 
        <>
          <Text alignSelf={'flex-start'} fontWeight='bold'> {`Reference Rate:`} </Text>
          <Tooltip label={<RateInfo/>} placement='bottom' bg={avatarBgColor}>
            <InfoIcon color={tooltipBgColor}/>
          </Tooltip>
        </>
        : <Text alignSelf={'flex-start'} fontWeight='bold'> {`Conversion Rate:`} </Text>
        }
      </HStack>
      {isLoadingRate
      ? <Box width={'full'}>
          <Skeleton h={'30px'} rounded="md"/>
        </Box>
      : 
      <Tooltip label={<RateAltView/>} placement={"bottom-start"} bg={avatarBgColor} minW="370px">
        <HStack>
          {isMultiRoute && orderType === "Limit"
          ?
          <PathRate 
            routingPath={routingPath}
            orderType={orderType}
            customRate={singleCustomRate}
            setIsEditing={setIsEditing}
            setHasEditedPrice={setHasEditedPrice}
            />
          :
          <Input
            type="number"
            variant={'unstyled'}
            width={"100%"}
            fontSize={"16px"}
            fontWeight={'extrabold'}
            isDisabled={orderType === 'Market' || isLockedOrder}
            value={orderType === 'Limit' && singleCustomRate 
            ? singleCustomRate 
            : Number(Number(bridgingRate).toFixed(destSym.displayDecimal))
            }
            onWheel={(e) => e.currentTarget.blur()}
            onChange={(e) => handleRateChange(e.target.value)}
          />
          }
          <Button fontSize={'12px'} onClick={handleRefreshRate} colorScheme={"facebook"} px={6} py={2} height={'80%'} isDisabled={timerClock > 0}>
            Refresh {timerClock > 0? `(${timerClock}s)` : ""}
          </Button>
        </HStack>
      </Tooltip>
      }
      <Slippage 
        orderType={orderType}
        slippagePercent={slippagePercent} 
        setSlippagePercent={setSlippagePercent}
        setHasEditedPrice={setHasEditedPrice}
        />
    </Flex>
  )
}
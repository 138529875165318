import { useEffect, useRef, useState } from "react";
import {
  Button,
  Stack,
  HStack,
  Text,
  Avatar,
  Tooltip,
} from "@chakra-ui/react";
import { truncateAddress } from "../../utils/truncate";
import { IoWalletOutline } from "react-icons/io5";
import jazzicon from "@metamask/jazzicon";
import { getZknsRecord } from "../../helpers/axiosHelpers";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useSelector } from "react-redux";
import { selectSourceChain } from "../../features/mesConvertSlice";
import { useAccount, useSwitchChain } from "wagmi";

export default function ConnectButton() {
  const avatarRef = useRef<HTMLDivElement | null>(null);
  const [zknsId, setZknsId] = useState<string>("");
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useAccount();
  const sourceChain = useSelector(selectSourceChain);
  const { switchChain } = useSwitchChain();

  //setting avatar and setting zkns id
  useEffect(() => {
    const element = avatarRef.current;
    if (element && address) {
      const addr = address.slice(2, 10);
      const seed = parseInt(addr, 16);
      const icon = jazzicon(20, seed); //generates a size 20 icon
      if (element.firstChild) {
        element.removeChild(element.firstChild);
      }
      element.appendChild(icon);
    }
    //get and set zkns id
    if(address){
      getZknsRecord(address)
      .then((res) => {
        if(res?.length > 0) setZknsId(res)
      })
    }
  }, [address]);

  //switch to selected source chain whenever connected / source chain state update
  useEffect(() => {
    if(!isConnected) return
    if(sourceChain.chainId === 0) return
    switchChain({chainId: sourceChain.chainId})
  }, [isConnected, sourceChain])

  return (
    <Stack
      direction={{ base: "column-reverse", lg: "row" }}
      className={"first-step"}
    >
      {address ? (
          <Button
            onClick={() => open({view: "Account"})}
          >
            <HStack>
              <div ref={avatarRef}></div>
              {zknsId.length > 0
              ? <Text fontSize={"sm"}> {zknsId} </Text>
              : <Text fontSize={"sm"}>{truncateAddress(address, 8)}</Text>
              }
              {sourceChain.chainId !== chainId && 
                <Tooltip label="Wrong Network" hasArrow arrowSize={15}>
                  <Avatar size={'sm'} src={'/warning_sign.svg'}/>
                </Tooltip>
              }
            </HStack>
          </Button>
      ) : (
        <Button
          onClick={() => open({view: "Connect"})}
          >
          <HStack spacing={2}>
            <IoWalletOutline />
            <Text fontSize={"sm"}>Connect Wallet</Text>
          </HStack>
        </Button>
      )}
    </Stack>
  );
}

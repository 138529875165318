import { Grid, GridItem, Heading } from "@chakra-ui/react";

export default function NotFound(){
  return(
    <Grid
    templateRows='repeat(10, 80px)'
    gap={1}
    bgColor='#0C141E'
    >
      <GridItem rowSpan={2} justifyContent='center' display='flex' alignItems='center'>
        <Heading size={'md'} color={'gray.200'}> Oops...this page does not exist 🤧</Heading>
      </GridItem>
    </Grid>
  )
}
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const userReceiptsSlice = createSlice({
  name: "userReceipts",
  initialState: {
    pendingReceipts: [],
    completedReceipts: [],
  },
  reducers: {
    setUserReceipts: (state, action) => {
      const receiptData = action.payload;
      return {
        pendingReceipts: receiptData.pendingReceipts,
        completedReceipts: receiptData.completedReceipts
      }
    }
  }
});

export const { setUserReceipts } = userReceiptsSlice.actions;

export const selectPendingReceipts = (state: RootState) => state.userReceipts.pendingReceipts;
export const selectCompletedReceipts = (state: RootState) => state.userReceipts.completedReceipts;

export default userReceiptsSlice.reducer;

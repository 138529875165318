import { Avatar, Flex, useColorModeValue } from "@chakra-ui/react";
import { FcPieChart } from "react-icons/fc";
import { Link } from "react-router-dom";

export default function PortfolioIcon() {
  const avatarBgColor = useColorModeValue('gray.300', 'gray.800')
  const avatarColor = useColorModeValue("cyan.600", "cyan.300")
  return (
    <Link
      to={"/portfolio"}
      role={'group'}
      >
      <Flex width={'full'} p={2} rounded={'md'} _hover={{ bg: useColorModeValue('gray.100', 'gray.900') }}>
        <Avatar bg={avatarBgColor} color={avatarColor} fontSize={{base: 'sm', lg: 'xl'}} size={{base: 'xs', lg: 'sm'}} icon={<FcPieChart/>}/>
      </Flex>
    </Link>
  );
}

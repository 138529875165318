import { MpOrderToSign, MpOrderToSubmit } from "../types"

export function getDataDomain(chainId: number) {
  return {
    name: 'MES',
    version: '1.0',
    chainId: chainId
  }
}

export const eip712OrderTypes = {
  MpOrder: [
    {name: 'tradingPair', type: 'string'},
    {name: 'orderDirection', type:'string'},
    {name: 'orderType', type:'string'},
    {name: 'price', type: 'string'},
    {name: 'payAmount', type: 'string'},
    {name: 'receiveAmount', type: 'string'},
    {name: 'route', type: 'string'},
    {name: 'chainId', type: 'string'},
    {name: 'destinationChainId', type: 'string'},
  ]
}

export const eip712CancelOrderTypes = {
  CancelMpOrder: [
    {name: 'cancelOrderKey', type:'string'},
    {name: 'userAddress', type: 'address'},
    {name: 'chainId', type: 'string'},
    {name: 'signTimestamp', type: 'uint256'},
  ]
}

export const eip712CancelAllUserOrderTypes = {
  CancelAllUserOrders: [
    {name: 'userAddress', type: 'address'},
    {name: 'signTimestamp', type: 'uint256'},
  ]
}

export const eip712DepositRequestTypes = {
  DepositRequest: [
    {name: 'userAddress', type: 'address'},
    {name: 'symbol', type: 'string'},
    {name: 'originatingChainId', type: 'string'},
    {name: 'depositAmount', type: 'string'}
  ]
}

export const eip712WithdrawRequestTypes = {
  WithdrawRequest: [
    {name: 'route', type: 'string'},
    {name: 'userAddress', type: 'address'},
    {name: 'symbol', type: 'string'},
    {name: 'destinationChainId', type: 'string'},
    {name: 'withdrawAmount', type: 'string'}
  ]
}

export function convertOrderIntoEip712Data(order:MpOrderToSubmit, isBridgingOrder: boolean):MpOrderToSign{
  //determine display syms for normal orders/bridging orders
  let payAmountDisplaySym: string 
  let receiveAmountDisplaySym: string
  if(isBridgingOrder) {
    payAmountDisplaySym = order.tradingPairSym[0]
    receiveAmountDisplaySym = order.tradingPairSym[1]
  } else {
    payAmountDisplaySym = order.orderDirectionDisplayId === 0? order.tradingPairSym[1] : order.tradingPairSym[0]
    receiveAmountDisplaySym = order.orderDirectionDisplayId === 0? order.tradingPairSym[0] : order.tradingPairSym[1]
  }
  const values : MpOrderToSign = {
    tradingPair: `${order.tradingPairSym[0]}-${order.tradingPairSym[1]}`,
    orderDirection: order.orderDirectionDisplayId === 0? 'Buy' : 'Sell',
    orderType: order.orderType,
    price: order.price.toString(),
    payAmount: `${order.payAmount} ${payAmountDisplaySym}`,
    receiveAmount: `${order.receiveAmount} ${receiveAmountDisplaySym}`,
    route: order.route,
    chainId: order.chainId.toString(),
    destinationChainId: order.destinationChainId? order.destinationChainId.toString() : "",
  }
  //adjustments for market orders
  if (order.orderType == 'Market'){
    values.price = '-'
    if (order.payAmount == 0){
      values.payAmount = '-'
    }else{
      values.receiveAmount = '-'
    }
  }
  return values
}
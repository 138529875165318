import { Box, Text, useColorModeValue } from "@chakra-ui/react";

export default function Footer() {

  return(
    <Box
      bg={useColorModeValue('#e7edf5','#070D15')}
      display='flex'
      minH={'100px'}
      alignItems='center'
      justifyContent='center'
    >
      <Text fontSize={'sm'}> Copyright © 2023 MES Protocol. All Rights Reserved. </Text>
    </Box>
  )
}

import { Fade, Flex, Input, Text, useColorModeValue, useRadio, useRadioGroup } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { countDecimalsWithNeg } from "../../utils/numbers";
import { useSelector } from "react-redux";
import { selectSourceSym, selectDestSym } from "../../features/mesConvertSlice";

export default function Slippage({
  orderType,
  slippagePercent,
  setSlippagePercent,
  setHasEditedPrice
}: {
  orderType: string,
  slippagePercent: string,
  setSlippagePercent: Dispatch<SetStateAction<string>>,
  setHasEditedPrice: Dispatch<SetStateAction<boolean>>
}){
  const [showCustom, setShowCustom] = useState<boolean>(false);
  const sourceSym = useSelector(selectSourceSym);
  const destSym = useSelector(selectDestSym);
  const { getRadioProps } = useRadioGroup({
    onChange: (value) => {
      if(isNaN(Number(value))){
        setShowCustom(true)
        return
      } else {
        setShowCustom(false)
      }
      setHasEditedPrice(true)
      setSlippagePercent(value)
    }
  })

  function handleSlippagePercentChange(percentInput: string){
    //check decimal, 1 dp allowed
    if(countDecimalsWithNeg(percentInput) <= 1){
      //check number input is between 0 to 99.9
      if(percentInput === "" || Number(percentInput) > -99.9 && Number(percentInput) < 99.9){
        const finalInput = percentInput.replace(/^0+(?=\d)/, '')
        setHasEditedPrice(true)
        setSlippagePercent(finalInput)
      }
    }
  }

  const slippagePercentageValues = ["0.5", "1", "Custom "]

  const SlippageBox = (props:any) => {
    const { getInputProps, getCheckboxProps } = useRadio(props)

    const input = getInputProps()
    const checkBox = getCheckboxProps()
    
    return(
      <Flex as="label">
        <input {...input}/>
        <Flex 
          {...checkBox}
          cursor="pointer"
          rounded={'md'} 
          px={{base:2, lg:4}}
          py={1}
          justifyContent="center"
          alignItems={"center"}
          bgColor={useColorModeValue("gray.400", "gray.600")}
          _checked={{
            bg: useColorModeValue("twitter.400", "twitter.800"),
            color: "white"
          }}
          whiteSpace={"nowrap"}
          >
          {props.children}
        </Flex>
      </Flex>
    )
  }
  return(
    <Flex 
      display={sourceSym.symbol === destSym.symbol && orderType === "Limit" ? "inherit" : "none"}
      justifyContent={'flex-start'}
      alignItems={"center"}
      flexDir={'row'} 
      fontSize={{base: "11px", lg: "13px"}} 
      gap={{base: 2, lg: 4}}
      >
      <Text>Slippage:</Text>
      {slippagePercentageValues.map((value, i) => {
        const radio = getRadioProps({value})
        return (
          <SlippageBox key={i} {...radio}>
            {value}%
          </SlippageBox>
        )
      })}
      <Fade in={showCustom}  style={{transitionDuration: "0.1s"}}>
        <Flex flexDir={'row'} alignItems="center">
          <Input
            type="number"
            width={{base: "50px", lg: "80px"}}
            height={"28px"}
            fontSize={{base: "11px", lg: "13px"}} 
            variant={'filled'}
            value={slippagePercent}
            onWheel={(e) => e.currentTarget.blur()}
            onChange={(e) => handleSlippagePercentChange(e.target.value)}
          />
          %
        </Flex>
      </Fade>
    </Flex>
  )
}